import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { FilterMatchMode } from 'primereact/api';
import { Dialog } from 'primereact/dialog';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import AddClassMasterDialog from './AddClassMaster';


const ClassMaster = () => {

    const [classList, setClassList] = useState([]);
    const [masterData, setMasterData] = useState([]);
    const [classId, setClassId] = useState('');
    const [visible, setVisible] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        ClassName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        board: { value: null, matchMode: FilterMatchMode.CONTAINS },
        medium: { value: null, matchMode: FilterMatchMode.CONTAINS },
        sections: { value: null, matchMode: FilterMatchMode.EQUALS },
        students: { value: null, matchMode: FilterMatchMode.EQUALS },
        totalCapacity: { value: null, matchMode: FilterMatchMode.EQUALS }
    });

    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [securityUserID, setSecurityUserID] = useState("");
    const [rows, setRows] = useState(10);
    const [first, setFirst] = useState(0);

    useEffect(() => {
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?._id);
        GetClassList();
    }, []);

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const onPageChange = (e) => {
        setFirst(e.first);
        setRows(e.rows);
    };

    const GetClassList = async () => {
        try {
            let data = {
                UserID: securityUserID,
            }
            await api.post(APIConstant.path.getClassList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setClassList(res.data);
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const actionBodyTemplate = (rows) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-text p-button-primary" onClick={() => {
                    setClassId(rows._id)
                    setMasterData(rows);
                    setIsEdit(true)
                    setVisible(true)
                }} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-text p-button-danger" />
            </React.Fragment>
        );
    };

    const renderColumnFilter = (field, filterMatchMode) => {
        return (
            <InputText
                value={filters[field]?.value || ''}
                onChange={(e) => {
                    let _filters = { ...filters };
                    _filters[field] = { value: e.target.value, matchMode: filterMatchMode };
                    setFilters(_filters);
                }}
                placeholder={`Search by ${field}`}
            />
        );
    };

    const onCancel = () => {
        setVisible(false);
        setClassId('');
        setIsEdit(false);
        setMasterData([]);
        GetClassList();
    }


    const actionBodyTemplateSrNo = (data, props) => {
        return props.rowIndex + 1;
    };
    return (
        <div className="datatable-filter">
            <div className="card">
                <div className="p-toolbar p-toolbar-group-left">
                    <h3>Class Master</h3>
                    <div className="p-toolbar p-toolbar-group-right">
                        <div className="p-input-icon-left global-search">
                            <i className="pi pi-search" />
                            <InputText
                                type="search"
                                value={globalFilterValue}
                                onChange={onGlobalFilterChange}
                                placeholder="Global Search"
                            />
                        </div>
                        <Button icon="pi pi-plus" className="p-button-rounded p-button-text" onClick={() => setVisible(true)} />
                    </div>
                </div>

                <DataTable
                    value={classList}
                    paginator
                    rows={rows}
                    first={first}
                    filters={filters}
                    filterDisplay="menu"
                    globalFilterFields={['ClassName', 'Board', 'Medium', 'SectionFullName', 'StudentListCount', 'TotalCapacity']}
                    responsiveLayout="scroll"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    rowsPerPageOptions={[5, 10, 20]}
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    onPage={onPageChange}
                >
                    <Column field="id" body={actionBodyTemplateSrNo} header="Sr No." />
                    <Column
                        field="ClassName"
                        header="Class No"
                        filter
                        filterElement={renderColumnFilter('ClassName', FilterMatchMode.CONTAINS)}
                    />
                    <Column
                        field="Board"
                        header="Board"
                        filter
                        filterElement={renderColumnFilter('Board', FilterMatchMode.CONTAINS)}
                    />
                    <Column
                        field="Medium"
                        header="Medium"
                        filter
                        filterElement={renderColumnFilter('Medium', FilterMatchMode.CONTAINS)}
                    />
                    <Column
                        field="SectionFullName"
                        header="No. of Sections"
                        filter
                        filterElement={renderColumnFilter('SectionFullName', FilterMatchMode.EQUALS)}
                    />
                    <Column
                        field="StudentListCount"
                        header="Students"
                        filter
                        filterElement={renderColumnFilter('StudentListCount', FilterMatchMode.EQUALS)}
                    />
                    <Column
                        field="TotalCapacity"
                        header="Total Capacity"
                        filter
                        filterElement={renderColumnFilter('TotalCapacity', FilterMatchMode.EQUALS)}
                    />
                    <Column body={actionBodyTemplate} header="Action" />
                </DataTable>
            </div>
            <Dialog
                header={classId ? "Edit Class Master" : "Add Class Master"}
                className='dialog-popup'
                visible={visible}
                onHide={() => onCancel()}
                draggable={false}
                
                position="top"
            >
                <AddClassMasterDialog masterData={masterData} isEdit={isEdit} ID={classId} onCancel={onCancel} />

            </Dialog>
        </div>
    );
};

export default ClassMaster;
