
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import React, { useState,useEffect } from 'react';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import Toast from '../../Shared/Toast/Toast';
import { FloatLabel } from 'primereact/floatlabel';

const AddSectionMasterDialog = (props) => {
    const [securityUserID, setSecurityUserID] = useState("");
    const [sectionName, setSectionName] = useState('');
    const [sectionNameErrText, setSectionNameErrText] = useState('');
    const [sectionNameErr, setSectionNameErr] = useState(false);
    const [totalCapacity, setTotalCapacity] = useState('');
    const [totalCapacityErrText, setTotalCapacityErrText] = useState('');
    const [totalCapacityErr, setTotalCapacityErr] = useState(false);
    
    useEffect(() => {
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?._id);
        if(props.ID){
            setSectionName(props?.masterData?.SectionName);
            setTotalCapacity(props?.masterData?.TotalCapacity);
        }
    
    },[]);

    const onCancel = () => {
        props.onCancel();
        setSectionName('');
        setTotalCapacity('');
    }

    const validate = () => {
        let formIsValid = true;
        if (CommonConfig.isEmpty(sectionName)) {
            formIsValid = false;
            setSectionNameErr(true);
            setSectionNameErrText("Section Name is required");
        } else {
            setSectionNameErr(false);
            setSectionNameErrText("");
        }
        if (CommonConfig.isEmpty(totalCapacity)) {
            formIsValid = false;
            setTotalCapacityErr(true);
            setTotalCapacityErrText("Total Capacity is required");
        } else {
            setTotalCapacityErr(false);
            setTotalCapacityErrText("");
        }
        return formIsValid;
    };

    const AddSectionMaster = async (e) => {
        if (validate(e)) {
            try {
                Loader.show();
                let data = {
                    id: props.ID,
                    SectionName: sectionName,
                    TotalCapacity: totalCapacity,
                    CreatedBy: securityUserID,
                };

                await api
                    .post(APIConstant.path.AddUpdateSectionMaster, data)
                    .then((response) => {
                        let res = response;
                        if (res.success) {
                            Toast.success({ message: res.message });
                            Loader.hide();
                            onCancel();
                        } else {
                            Toast.error({ message: res.message });
                            Loader.hide();
                            onCancel();
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        Loader.hide();
                        onCancel();
                    });
            } catch (err) {
                console.log(err);
                Loader.hide();
                onCancel();
            }
        }
    };

    return (
        <div className="user-container p-d-flex p-flex-column p-jc-between">
            <div>
                <div className="p-col-12 p-md-4 p-pt-2">
                    <div className="">
                        <FloatLabel>
                            <label htmlFor="clasName">Section Name <span style={{ color: 'red' }}>*</span>: </label>
                            <InputText
                                className="p-mt-2"
                                id="sectionName"
                                type="text"
                                placeholder="Section Name"
                                value={sectionName}
                                autoFocus={true}
                                onChange={(e) => setSectionName(e.target.value)}
                            />
                        </FloatLabel>
                        <span className="error-msg" style={{ color: 'red' }}>
                            {sectionNameErr ? sectionNameErrText : null}
                        </span>
                    </div>
                </div>
                <div className="p-col-12 p-md-4 p-pt-2">
                    <div className="">
                        <FloatLabel>
                            <label htmlFor="totalCapacity">Total Capacity : </label>
                            <InputText
                                className="p-mt-2"
                                id="totalCapacity"
                                type="number"
                                placeholder="Total Capacity"
                                value={totalCapacity}
                                autoFocus={true}
                                onChange={(e) => setTotalCapacity(e.target.value)}
                            />
                        </FloatLabel>
                        <span className="error-msg" style={{ color: 'red' }}>
                            {totalCapacityErr ? totalCapacityErrText : null}
                        </span>
                    </div>
                </div>

            </div>
            <div className="card-footer">
                <Button label="Submit" severity="secondary" raised onClick={(e) => AddSectionMaster()} />
                <Button className='btn-cancel' onClick={(e) => onCancel()}> cancel </Button>
            </div>
        </div>
    );
};

export default AddSectionMasterDialog;
