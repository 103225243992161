import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import React, { useState, useEffect } from 'react';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import Toast from '../../Shared/Toast/Toast';
import { FloatLabel } from 'primereact/floatlabel';

const AddTeacherMasterDialog = (props) => {
    const [securityUserID, setSecurityUserID] = useState("");
    const [teacherID, setTeacherID] = useState("");
    const [teacherName, setTeacherName] = useState('');
    const [teacherNameErrText, setTeacherNameErrText] = useState('');
    const [teacherNameErr, setTeacherNameErr] = useState(false);
    const [emailId, setEmailId] = useState('');
    const [emailIdErrText, setEmailIdErrText] = useState('');
    const [emailIdErr, setEmailIdErr] = useState(false);
    const [phoneNo, setPhoneNo] = useState('');
    const [phoneNoErrText, setPhoneNoErrText] = useState('');
    const [phoneNoErr, setPhoneNoErr] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordErrText, setPasswordErrText] = useState('');
    const [passwordErr, setPasswordErr] = useState(false);
    const [isPasswordSeen, setIsPasswordSeen] = useState(false);
    const [accountTypeList, setAccountTypeList] = useState([]);
    const [userPlanList, setUserPlanList] = useState([]);
    const [userTypeList, setUserTypeList] = useState([]);

    useEffect(() => {
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?._id);
        generateTeacherId();
        getAccountType();
        GetUserRoleList();
        GetUserPlanList();
    }, []);

    const generateTeacherId = () => {
        const prefix = 'T';
        const randomNumber = Math.floor(100000 + Math.random() * 900000);
        const newTeacherId = `${prefix}${randomNumber}`;
        setTeacherID(newTeacherId);
    };

    const onCancel = () => {
        props.onCancel();
        setTeacherName('');
        setEmailId('');
        setTeacherID('');
        setPhoneNo('');
        setPassword('');
    }

    const validate = () => {
        let formIsValid = true;
        if (CommonConfig.isEmpty(teacherName)) {
            formIsValid = false;
            setTeacherNameErr(true);
            setTeacherNameErrText("Teacher Name is required");
        } else {
            setTeacherNameErr(false);
            setTeacherNameErrText("");
        }
        if (CommonConfig.isEmpty(emailId)) {
            formIsValid = false;
            setEmailIdErr(true);
            setEmailIdErrText("EmailId is required");
        } else {
            setEmailIdErr(false);
            setEmailIdErrText("");
        }
        if (CommonConfig.isEmpty(phoneNo)) {
            formIsValid = false;
            setPhoneNoErr(true);
            setPhoneNoErrText("PhoneNo is required");
        } else {
            setPhoneNoErr(false);
            setPhoneNoErrText("");
        }
        if (CommonConfig.isEmpty(password)) {
            formIsValid = false;
            setPasswordErr(true);
            setPasswordErrText("Password is required");
        } else {
            setPasswordErr(false);
            setPasswordErrText("");
        }
        return formIsValid;
    };

    const getAccountType = async () => {
        try {
          let data = {
            Type: "AccountType",
          };
          await api
            .post(APIConstant.path.GetStringMap, data)
            .then(async (response) => {
              let res = response;
              if (res.success) {
                setAccountTypeList(
                  res.data.map((data) => ({ label: data.Name, value: data._id }))
                );
                Loader.hide();
              }
            })
            .catch((err) => {
              Loader.hide();
              console.log(err);
            });
        } catch (err) {
          console.log("err", err);
        }
      };

    const GetUserRoleList = async (value) => {
        let params = {
          AccountType: value,
        };
        try {
          await api
            .post(APIConstant.path.GetUserRole, params)
            .then(async (response) => {
              let res = response;
              if (res.success) {
                setUserTypeList(
                  res.data.map((role) => ({
                    label: role.RoleName,
                    value: role._id,
                  }))
                );
                Loader.hide();
              }
            })
            .catch((err) => {
              Loader.hide();
              console.log(err);
            });
        } catch (err) {
          console.log(err);
        }
      };
    
      const GetUserPlanList = async (UserType, AccountType) => {
        try {
          var params = {
            AccountType: AccountType,
            UserType: UserType,
          };
          await api
            .post(APIConstant.path.getUserPlanList, params)
            .then((res) => {
              if (res.success) {
                setUserPlanList(
                  res.data.map((plan) => ({
                    ...plan,
                    label: plan.PlanName,
                    value: plan._id,
                  }))
                );
                Loader.hide();
              }
            })
            .catch((err) => {
              Loader.hide();
              console.log(err);
            });
        } catch (err) {
          console.log(err);
        }
      };

    const AddTeacherMaster = async (e) => {
        if (validate(e)) {
            try {
                let data = {
                  id: '',
                  ProfileId: teacherID,
                  Name: teacherName,
                  Email: emailId,
                  Password: password,
                  UserType: userTypeList.find((x) => x.label === 'Teacher')?.value,
                  Phone: phoneNo,
                  PlanId: userPlanList.find((plan) => plan.label === 'BasicPlan')?.value,
                  isMailVerified: 1,
                  isPhoneVerified: 1,
                  AccountType: accountTypeList.find((x) => x.label === "New Member")?.value,
                  CreatedBy: securityUserID
                };
                
                Loader.show();
                await api.post(APIConstant.path.AddUpdateUser, data).then(async response => {
                    let res = response;
                    if (res.success) {
                      Toast.success({ message: res.message });
                      Loader.hide();
                      onCancel();
                    } else {
                      Toast.error({ message: res.message });
                      Loader.hide();
                      onCancel();
                    }
                  })
                  .catch((err) => {
                    Loader.hide();
                    onCancel();
                  });
              } catch (err) {
                console.log(err);
                Loader.hide();
                onCancel();
              }
        }
    };

    return (
        <div className="user-container p-d-flex p-flex-column p-jc-between">
            <div>
                <div className="p-col-12 p-md-4 p-pt-2">
                    {/* <div className=""> */}
                        <label htmlFor="clasName">Teacher ID </label>
                        <InputText
                            className="p-mt-2"
                            id="teacherName"
                            type="text"
                            value={teacherID}
                            disabled={true}
                        />
                    {/* </div> */}
                </div>
                <div className="p-col-12 p-md-4 p-pt-2">
                    <div className="">
                        <FloatLabel>
                            <label htmlFor="teacherName">Teacher Name</label>
                            <InputText
                                className="p-mt-2"
                                id="teacherName"
                                type="text"
                                placeholder="Teacher Name"
                                value={teacherName}
                                autoFocus={true}
                                onChange={(e) => setTeacherName(e.target.value)}
                            />
                        </FloatLabel>
                        <span className="error-msg" style={{ color: 'red' }}>
                            {teacherNameErr ? teacherNameErrText : null}
                        </span>
                    </div>
                </div>
                <div className="p-col-12 p-md-4 p-pt-2">
                    <div className="">
                        <FloatLabel>
                            <label htmlFor="emailId">EmailId </label>
                            <InputText
                                className="p-mt-2"
                                id="emailId"
                                type="email"
                                placeholder="EmailId"
                                value={emailId}
                                autoFocus={true}
                                onChange={(e) => setEmailId(e.target.value)}
                            />
                        </FloatLabel>
                        <span className="error-msg" style={{ color: 'red' }}>
                            {emailIdErr ? emailIdErrText : null}
                        </span>
                    </div>
                </div>
                <div className="p-col-12 p-md-4 p-pt-2">
                    <div className="">
                        <FloatLabel>
                            <label htmlFor="phoneNo">Phone No </label>
                            <InputText
                                className="p-mt-2"
                                id="phoneNo"
                                type="number"
                                placeholder="PhoneNo"
                                value={phoneNo}
                                onChange={(e) => setPhoneNo(e.target.value)}
                            />
                        </FloatLabel>
                        <span className="error-msg" style={{ color: 'red' }}>
                            {phoneNoErr ? phoneNoErrText : null}
                        </span>
                    </div>
                </div>
                <div className="p-col-12 p-md-4 p-pt-2">
                    <div className='custom-inputs password-field'>
                        <FloatLabel>
                            <label htmlFor="password">Password </label>
                            <InputText
                                id='password'
                                type={isPasswordSeen ? 'text' : 'password'}
                                placeholder='******'
                                className='p-w-100 p-mt-2'
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <i
                                className={
                                    isPasswordSeen
                                        ? 'icon-view tailer-icon'
                                        : 'icon-view-off tailer-icon'
                                }
                                onClick={() =>
                                    setIsPasswordSeen(!isPasswordSeen)
                                }
                            ></i>
                        </FloatLabel>
                        <span className="error-msg" style={{ color: 'red' }}>
                            {passwordErr ? passwordErrText : null}
                        </span>
                    </div>
                </div>

            </div>
            <div className="card-footer">
                <Button label="Submit" severity="secondary" raised onClick={(e) => AddTeacherMaster()} />
                <Button className='btn-cancel' onClick={(e) => onCancel()}> cancel </Button>
            </div>
        </div>
    );
};

export default AddTeacherMasterDialog;
