import React from 'react';
import logoImage from '../../assets/images/image 1.png';
import profileImage from '../../assets/images/image 3.png';
// import './IdCardPdf.css';

const ExportIdCardPDF = (props) => {
    const { fileName, templateConfiguration, pdfData, classSectionName } = props;
    const pdfBlocks = [];
    pdfData.map((x, idx) => {
        pdfBlocks.push(
            <div className="p-position-relative">
                <h4>ID Card</h4>
                <hr />
                <div className="main-body">
                    <div className="card-parent">
                        <div className="card">
                            {templateConfiguration?.HeaderSection?.includes('schoolLogo') ?
                            <div className="logo-box">
                                <img
                                    src={logoImage}
                                    alt="School Logo"
                                    className="logo"
                                />
                            </div> : ''}
                            {/* Information Box */}
                            <div className="Info-box">
                                <div className="background-box">
                                    <div className="background"></div>
                                </div>
                                {templateConfiguration?.BodySection?.includes('academicYear') ?
                                <div className="current-year">
                                    <p className="year-text">2024</p>
                                    <p className="year-text">-</p>
                                    <p className="year-text">2025</p>
                                </div> : ''}

                                {templateConfiguration?.BodySection?.includes('"studentProfilePic') ?
                                <div className="profile-box">
                                    <img
                                        src={profileImage}
                                        alt="Profile"
                                        className="profile"
                                    />
                                </div> : ''}
                                {templateConfiguration?.HeaderSection?.includes('classSection') ?
                                <p className="standerd-text">{classSectionName}</p> : ''}

                                {templateConfiguration?.BodySection?.includes('studentName') ?
                                <div className="name">
                                    <p className="name-text">{x?.Name}</p>
                                </div> : ''
                                }

                                {/* Information Fields */}
                                <div className="info-main">
                                {templateConfiguration?.BodySection?.includes('fatherName') ?
                                    <div className="inner-info">
                                        <p className="inner-info-text">Father</p>
                                        <p className="inner-info-subtext">
                                            <span className="dot">:</span> Abhishek Sharma
                                        </p>
                                    </div> : ''}
                                    {templateConfiguration?.BodySection?.includes('dob') ?
                                    <div className="inner-info">
                                        <p className="inner-info-text">G.R</p>
                                        <p className="inner-info-subtext">
                                            <span className="dot">:</span> 2889
                                        </p>
                                    </div> : ''}
                                    {templateConfiguration?.BodySection?.includes('dob') ?
                                    <div className="inner-info">
                                        <p className="inner-info-text">D.O.B.</p>
                                        <p className="inner-info-subtext">
                                            <span className="dot">:</span> 16-Nov-2011
                                        </p>
                                    </div> : ''}
                                    {templateConfiguration?.BodySection?.includes('fatherContactNo') ?
                                    <div className="inner-info">
                                        <p className="inner-info-text">Contact</p>
                                        <p className="inner-info-subtext">
                                            <span className="dot">:</span> 9904419938
                                        </p>
                                    </div> : ''}
                                    {templateConfiguration?.BodySection?.includes('address') ?
                                    <div className="inner-info">
                                        <p className="inner-info-text">Address</p>
                                        <p className="inner-info-subtext font-size">
                                            <span className="dot">:</span> Kavya Residency, Plot
                                            NO-154, Varsamedi, Shantidham
                                        </p>
                                    </div> : ''}
                                </div>

                                {/* Signature Section */}
                                <div className="p-signature">
                                    <p className="inner-info-subtext p-width">Test</p>
                                    <p className="inner-info-text p-width">{templateConfiguration?.AuthorityTitle}</p>
                                </div>
                            </div>

                            {templateConfiguration?.HeaderSection?.includes('schoolAddress') ?
                            <div className="footer-text-box">
                                <p className="footer-text">
                                    8479204841, ssgurukulgandhidham@gmail.com
                                </p>
                            </div> : ''}
                        </div>
                    </div>
                </div>
            </div>
        )
    });
    return pdfBlocks;
};

export default ExportIdCardPDF;
