import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import api from '../../utils/apiClient';
import APIConstant from '../../utils/pathConstants';
import Toast from '../Shared/Toast/Toast';
import Loader from '../Shared/Loader/Loader';
import logo from '../../assets/svgs/portlink-logo.svg';
import { Link, useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';

const Login = () => {
  const initialState = {
    userName: '',
    password: '',
    userNameErr: false,
    UserhelperText: '',
    passwordErr: false,
    passwordhelperText: '',
    IsPasswordSeen: false,
    openLoginConfirm: false
  };

  const [state, setState] = useState(initialState);
  const navigate = useNavigate();

  const handleCheck = (e, type) => {
    let value = e.target.value;

    if (type === 'userName') {
      if (value === '' || value === null) {
        setState((prevState) => ({
          ...prevState,
          userName: value,
          userNameErr: true,
          UserhelperText: 'Username is required',
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          userName: value,
          userNameErr: false,
          UserhelperText: '',
        }));
      }
    }

    if (type === 'Password') {
      if (value === '' || value === null) {
        setState((prevState) => ({
          ...prevState,
          password: value,
          passwordErr: true,
          passwordhelperText: 'Password is required',
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          password: value,
          passwordErr: false,
          passwordhelperText: '',
        }));
      }
    }
  };

  const validate = () => {
    let formIsValid = true;

    if (state.userName === '' || state.userName === null) {
      setState((prevState) => ({
        ...prevState,
        userNameErr: true,
        UserhelperText: 'Username is required',
      }));
      formIsValid = false;
    } else {
      setState((prevState) => ({
        ...prevState,
        userNameErr: false,
        UserhelperText: '',
      }));
    }

    if (state.password === '' || state.password === null) {
      setState((prevState) => ({
        ...prevState,
        passwordErr: true,
        passwordhelperText: 'Password is required',
      }));
      formIsValid = false;
    } else {
      setState((prevState) => ({
        ...prevState,
        passwordErr: false,
        passwordhelperText: '',
      }));
    }

    return formIsValid;
  };

  const handleLogin = async (e, isAlready) => {
    e.preventDefault();
    if (validate()) {
      try {
        let data = {
          Phone: state.userName,
          Password: state.password,
          DeviceType: "Web",
          isAlreadyLogin: isAlready,
          IsOTPLogin: false,
        };
        Loader.show();

        const res = await api.post(APIConstant.path.login, data);
        if (res.success) {
          localStorage.setItem('loginData', JSON.stringify(res.data));
          localStorage.setItem('isAuthorized', res.success);
          getUserAccessList(res?.data._id);
          Loader.hide();
          Toast.success({ message: res.message });
          navigate('/dashboard');
        } else {
          if(res?.message === 'User is already logged in'){
            setState((prevState) => ({
              ...prevState,
              openLoginConfirm: true
            }))
          }
          else{
          Toast.error({ message: res.message });
        }
          Loader.hide();
        }
      } catch (err) {
        Loader.hide();
      }
    }
  };

  const getUserAccessList = async (userId) => {
    try {
      const data = { UserList: [userId] };
      const res = await api.post(APIConstant.path.getUserWiseAccessList, data);
      if (res.success) {
        localStorage.setItem('userAccessData', JSON.stringify(res?.data[0]?.ServiceList));
      }
    } catch (err) {
      console.error('Error fetching user access:', err);
    }
  };

  const handleClose = () => {
    setState((prevState) => ({
      ...prevState,
      openLoginConfirm: false,
    }));
  };

  const renderFooter = () => (
    <div className="p-d-flex p-jc-end">
      <Button
        label="Cancel"
        className="p-button-outlined p-button-secondary"
        onClick={handleClose}
      />
      <Button
        label="Continue"
        className="p-button-primary"
        onClick={(e) => handleLogin(e, 'true')}
      />
    </div>
  );

  return (
    <div className='auth-container'>
      <div className='p-grid p-ai-center login-background p-h-100'>
        <div className='p-col-12 p-md-7 p-h-100 p-p-0 p-d-none p-d-lg-block p-position-relative'>
          <div className='login-bg-box'>
            <div className='login-bg-text'>
              <h1>The Future Of Maritime & Supply Chain</h1>
              <p>
                Sailing Towards an Automated and Efficient Future. Discover the
                Wave of Innovation in Maritime and Supply Chain.
              </p>
            </div>
          </div>
        </div>

        <div className='p-col-12 p-md-5'>
          <div className='form-area p-col-12 p-md-9'>
            <img
              src={logo}
              alt='logo'
              className='img-thumbnail rounded-circle mx-auto'
            />
            <p className='form-heading my-5'>Sign in to your account</p>
            <form onSubmit={(e) => handleLogin(e, 'false')}>
              <div className='p-w-100'>
                <div className='custom-inputs'>
                  <label htmlFor='emailAddress'>Username</label>
                  <InputText
                    id='emailAddress'
                    placeholder='info@yourmail.com'
                    type='text'
                    className='p-w-100 p-mt-2'
                    value={state.userName}
                    onChange={(e) => handleCheck(e, 'userName')}
                  />
                </div>
                {state.userNameErr && (
                  <small
                    id='username2-help'
                    className='p-error block p-d-block p-text-left p-pt-2'
                  >
                    {state.UserhelperText}
                  </small>
                )}
              </div>

              <div className='p-w-100 p-mt-4'>
                <div className='custom-inputs password-field'>
                  <label htmlFor='password'>Password</label>
                  <InputText
                    id='password'
                    type={state.IsPasswordSeen ? 'text' : 'password'}
                    placeholder='******'
                    className='p-w-100 p-mt-2'
                    value={state.password}
                    onChange={(e) => handleCheck(e, 'Password')}
                  />
                  <i
                    className={
                      state.IsPasswordSeen
                        ? 'icon-view tailer-icon'
                        : 'icon-view-off tailer-icon'
                    }
                    onClick={() =>
                      setState((prevState) => ({
                        ...prevState,
                        IsPasswordSeen: !prevState.IsPasswordSeen,
                      }))
                    }
                  ></i>
                </div>
                {state.passwordErr && (
                  <small
                    id='username2-help'
                    className='p-error block p-d-block p-text-left p-pt-2'
                  >
                    {state.passwordhelperText}
                  </small>
                )}
              </div>

              <div className='p-d-flex p-ai-center p-jc-between p-my-4'>
                <Link to='/forgot-password' className='forgot-box'>
                  Forgot Password?
                </Link>
              </div>
              <Button
                type='submit'
                className='p-w-100 login-btn'
                label='Login'
              />
            </form>
          </div>
        </div>
      </div>
      <Dialog
        visible={state.openLoginConfirm}
        onHide={handleClose}
        header={
          <div className="p-d-flex p-jc-between p-ai-center">
            <span>Login Confirmation</span>
            {/* <i
              className="pi pi-times icon-modal-close"
              onClick={handleClose}
              style={{ cursor: 'pointer' }}
            ></i> */}
          </div>
        }
        footer={renderFooter()}
        draggable={false}
        dismissableMask
        style={{ width: '450px' }}
      >
        <div>
          <p>
            It seems like you are already logged in on another device. Are you sure you want to logout from all other devices and continue?
          </p>
        </div>
      </Dialog>
    </div>
  );
};

export default Login;
