import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { RadioButton } from 'primereact/radiobutton';
import { Calendar } from 'primereact/calendar';
import { Chips } from 'primereact/chips';
import userImage from '../../../assets/images/UserImage.png';
import { TabView, TabPanel } from 'primereact/tabview';
import '../../../assets/css/teacherMasterDetail.css';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import { InputTextarea } from 'primereact/inputtextarea';
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import Toast from '../../Shared/Toast/Toast';
import { Navigate, useNavigate } from 'react-router-dom';
import moment from 'moment';

const ViewEditParentDetails = (props) => {
    const navigate = useNavigate();
    const [securityUserID, setSecurityUserID] = useState("");
    const [personalDetailId, setPersonalDetailId] = useState('');
    const [fullName, setFullName] = useState("");
    const [parentId, setParentId] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [aadharNo, setAadharNo] = useState('');
    const [aadharOTP, setAadharOTP] = useState('');
    const [isAadharVerify, setIsAadharVerify] = useState(false);
    const [dob, setDob] = useState('');
    const [age, setAge] = useState('');
    const [currentAddress, setCurrentAddress] = useState('');
    const [permanentAddress, setPermanentAddress] = useState('');
    const [qualification, setQualification] = useState('');
    const [occupation, setOccupation] = useState('');
    const [designation, setDesignation] = useState('');
    const [income, setIncome] = useState('');
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?._id);
        getUserDetailById();
        getPersonDetail();
    }, []);

    const onReferesh = () => {
        getUserDetailById();
        getPersonDetail();
    }

    const getUserDetailById = async (ID) => {
        try {
            let data = {
                id: props?.location?.state?.userId,
            };
            await api
                .post(APIConstant.path.getUserById, data)
                .then(async (response) => {
                    let res = await response;
                    if (res.success) {
                        Loader.hide();
                        setFullName(res?.data?.Name);
                        setMobileNumber(res?.data?.Phone);
                        setEmailAddress(res?.data?.Email);
                    }
                })
                .catch((err) => {
                    Loader.hide();
                    console.log(err);
                });
        } catch (err) {
            console.log(err);
        }
    };

    const getPersonDetail = async (ID) => {
        try {
            let data = {
                id: props?.location?.state?.userId,
            };
            await api
                .post(APIConstant.path.GetPersonalDetailById, data)
                .then(async (response) => {
                    let res = await response;
                    if (res.success) {
                        Loader.hide();
                        setPersonalDetailId(res?.data[0]?._id);
                        setParentId(res?.data[0]?.ProfileId);
                        setAadharNo(res?.data[0]?.AadharNo);
                        setDob(res?.data[0]?.DOB ? new Date(res?.data[0]?.DOB) : '');
                        setAge(res?.data[0]?.Age);
                        setCurrentAddress(res?.data[0]?.CurrentAddress);
                        setPermanentAddress(res?.data[0]?.PermanentAddress);
                        setQualification(res?.data[0]?.Qualification);
                        setOccupation(res?.data[0]?.Occupation);
                        setDesignation(res?.data[0]?.Designation);
                        setIncome(res?.data[0]?.Income)
                    }
                })
                .catch((err) => {
                    Loader.hide();
                    console.log(err);
                });
        } catch (err) {
            console.log(err);
        }
    };

    const saveDetails = async (e) => {
        await AddEditPersonalDetail();
        await onReferesh();
    }

    const AddEditPersonalDetail = async (e) => {
        try {
            let data = {
                id: personalDetailId,
                UserId: props?.location?.state?.userId,
                AadharNo: aadharNo,
                Age: age,
                DOB: moment(dob).format("YYYY-MM-DD"),
                CurrentAddress: currentAddress,
                PermanentAddress: permanentAddress,
                Qualification: qualification,
                Occupation: occupation,
                Designation: designation,
                Income: income,
                CreatedBy: securityUserID,
            };
            Loader.show();
            await api.post(APIConstant.path.AddPersonalDetail, data).then(async response => {
                let res = response;
                if (res.success) {
                    Toast.success({ message: 'Details Updated' });
                    Loader.hide();
                } else {
                    Toast.error({ message: res.message });
                    Loader.hide();
                }
            })
                .catch((err) => {
                    Loader.hide();
                });
        } catch (err) {
            console.log(err);
            Loader.hide();
        }
    };

    return (
        <div className="teacher-details">
            <div className="profile-header">
                <img src={userImage} alt="Profile" className="profile-pic" />
                <div className="profile-info">
                    <h2>{fullName}</h2>
                    <p>Parent ID - {parentId}</p>
                </div>
            </div>

            <div className="form-section">
                <div className="teacher-details-right">
                    <Button icon="icon-edit tailer-icon" label='Edit Profile' className="p-button-outlined p-button-primary" onClick={(e) => setIsEdit(!isEdit)} />
                </div>
                <div className="p-fluid">
                    <div>
                        <div className="p-field">
                            <label htmlFor="fullName">Full Name</label>
                            <InputText id="fullName" value={fullName} disabled={!isEdit} onChange={(e) => setFullName(e.target.value)} />
                        </div>
                        <div className="p-field">
                            <label htmlFor="aadhar">Aadhar</label>
                            <div className="p-inputgroup">
                                <InputText id="aadhar" disabled={!isEdit} onChange={(e) => setAadharNo(e.target.value)} />
                                <Button label="Verify" disabled={!isEdit} />
                            </div>
                        </div>
                        <div className="p-field">
                            <label htmlFor="emailId">Email ID</label>
                            <InputText id="emailId" value={emailAddress} disabled={!isEdit} onChange={(e) => setEmailAddress(e.target.value)} />
                        </div>
                        <div className="p-field">
                            <label htmlFor="mobileNo">Contact No.</label>
                            <InputText id="mobileNo" value={mobileNumber} disabled={!isEdit} onChange={(e) => setMobileNumber(e.target.value)} />
                        </div>
                        <div className="p-field">
                            <label htmlFor="dob">Date of Birth</label>
                            <Calendar id="dob" value={dob} onChange={(e) => setDob(e.value)} showIcon disabled={!isEdit} />
                        </div>
                        <div className="p-field">
                            <label htmlFor="age">Age</label>
                            <InputText id="age" value={age} disabled={!isEdit} onChange={(e) => setAge(e.target.value)} />
                        </div>
                        <div className="p-field">
                            <label htmlFor="qualification">Qualification</label>
                            <InputText id="qualification" value={qualification} disabled={!isEdit} onChange={(e) => setQualification(e.target.value)} />
                        </div>
                        <div className="p-field">
                            <label htmlFor="occupation">Occupation</label>
                            <InputText id="occupation" value={occupation} disabled={!isEdit} onChange={(e) => setOccupation(e.target.value)} />
                        </div>
                        <div className="p-field">
                            <label htmlFor="designation">Designation</label>
                            <InputText id="designation" value={designation} disabled={!isEdit} onChange={(e) => setDesignation(e.target.value)} />
                        </div>
                        <div className="p-field">
                            <label htmlFor="income">Annual Income</label>
                            <InputText id="income" value={income} disabled={!isEdit} onChange={(e) => setIncome(e.target.value)} />
                        </div>
                        <div className="p-field">
                            <label htmlFor="currentAddress">Current Address</label>
                            <InputTextarea id="currentAddress" value={currentAddress} type='textArea' rows={5} cols={30} onChange={(e) => setCurrentAddress(e.target.value)} disabled={!isEdit} />
                        </div>
                        <div className="p-field">
                            <label htmlFor="permanentAddress">Permanent Address</label>
                            <InputTextarea id="permanentAddress" value={permanentAddress} rows={5} cols={30} onChange={(e) => setPermanentAddress(e.target.value)} disabled={!isEdit} />
                        </div>
                    </div>

                    <div className="form-actions">
                        {isEdit ?
                            <Button label="Save" icon="pi pi-check" onClick={() => saveDetails()} /> : null}
                        <Button label="Back" className="p-button-secondary" onClick={() => navigate('/parent-master')} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ViewEditParentDetails;
