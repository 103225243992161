import React from 'react';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import '../../../assets/css/teacherMaster.css';
import userImage from '../../../assets/images/UserImage.png';
import { useNavigate } from 'react-router-dom';

const TeacherCard = ({ teacher }) => {
    const navigate  = useNavigate();
    return (
        <div className="teacher-card">
            <img src={teacher?.image ?? userImage} alt={teacher?.name ?? userImage} className="teacher-image" />
            <h3>{teacher?.Name}</h3>
            <p>{teacher?.Status}</p>
            <div className="subjects">
                {teacher?.subjects?.map(subject => (
                    <Tag key={subject} value={subject} />
                ))}
            </div>
            <div className="actions">
                <Button label="View Profile" className="p-button-outlined p-button-primary" onClick={() => navigate('/viewEdit-teacher-master',{ state: { userId: teacher?._id, isEdit: false }} )} />
                <Button label="Edit Profile" className="p-button-outlined p-button-secondary" onClick={() => navigate('/viewEdit-teacher-master', { state: { userId: teacher?._id, isEdit:true } })} />
            </div>
        </div>
    );
}

export default TeacherCard;
