import React, { useState,useEffect } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import TeacherCard from './TeacherCard';
import '../../../assets/css/teacherMaster.css';
import { useNavigate } from 'react-router-dom';
import AddTeacherMasterDialog from './AddTeacherMaster';
import { Dialog } from 'primereact/dialog';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';

const TeacherMaster = () => {
    const navigate  = useNavigate();
    const [securityUserID, setSecurityUserID] = useState("");
    const [sortOption, setSortOption] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [visible, setVisible] = useState(false);
    const sortOptions = [
        { label: 'Newest', value: 'newest' },
        { label: 'Oldest', value: 'oldest' },
        { label: 'Alphabetical', value: 'alphabetical' }
    ];

    const [teachersList,setTeachersList] = useState([]);

    useEffect(() => {
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?._id);
        GetTeachersList();
    }, []);

    const onCancel = () => {
        setVisible(false);
        GetTeachersList();
    }

    const GetTeachersList = async () => {
        try {
            let data = {
                UserID: securityUserID,
            }
            await api.get(APIConstant.path.getUser, data).then(async response => {
                let res = response;
                if (res.success) {
                    setTeachersList(res.data.filter((x) => x.UserType === 'Teacher'));
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    return (
        <div className="teacher-master">
            <div className="header">
                <h2>Teacher Master</h2>
                <div className="filters">
                    <Dropdown value={sortOption} options={sortOptions} onChange={(e) => setSortOption(e.value)} placeholder="Newest" />
                    <Dropdown value={sortOption} options={sortOptions} onChange={(e) => setSortOption(e.value)} placeholder="Oldest" />
                    <Dropdown value={sortOption} options={sortOptions} onChange={(e) => setSortOption(e.value)} placeholder="Alphabetical" />
                </div>
                <div className="search-bar">
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} placeholder="Global Search" />
                    </span>
                    <Button icon="pi pi-plus" className="p-button-rounded p-button-text" onClick={() => setVisible(true)} />
                </div>
                
            </div>
            <div className="teacher-grid">
                {teachersList.map(teacher => (
                    <TeacherCard key={teacher._id} teacher={teacher} />
                ))}
            </div>
            <Dialog
                header="Add Teacher Master"
                className='dialog-popup'
                visible={visible}
                onHide={() => onCancel()}
                draggable={false}
                
                position="top"
            >
                <AddTeacherMasterDialog onCancel={onCancel} />

            </Dialog>
        </div>
    );
}

export default TeacherMaster;
