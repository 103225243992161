import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { RadioButton } from 'primereact/radiobutton';
import { Calendar } from 'primereact/calendar';
import { Chips } from 'primereact/chips';
import userImage from '../../../assets/images/UserImage.png';
import { TabView, TabPanel } from 'primereact/tabview';
import '../../../assets/css/teacherMasterDetail.css';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import { InputTextarea } from 'primereact/inputtextarea';
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import Toast from '../../Shared/Toast/Toast';
import { Navigate, useNavigate } from 'react-router-dom';
import moment from 'moment';

const ViewEditStudentDetails = (props) => {
    const navigate = useNavigate();
    const [securityUserID, setSecurityUserID] = useState("");
    const [personalDetailId, setPersonalDetailId] = useState('');
    const [fullName, setFullName] = useState("");
    const [studentId, setStudentId] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [alternateMobileNumber, setAlternateMobileNumber] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [selectedGender, setSelectedGender] = useState(null);
    const [selectedBloodGroup, setSelectedBloodGroup] = useState(null);
    const [selectedNationality, setSelectedNationality] = useState(null);
    const [selectedReligion, setSelectedReligion] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [languages, setLanguages] = useState([]);
    const [aadharNo, setAadharNo] = useState('');
    const [aadharOTP, setAadharOTP] = useState('');
    const [isAadharVerify, setIsAadharVerify] = useState(false);
    const [dob, setDob] = useState('');
    const [doa, setDoa] = useState('');
    const [age, setAge] = useState('');
    const [pinCode, setPinCode] = useState('');
    const [currentAddress, setCurrentAddress] = useState('');
    const [permanentAddress, setPermanentAddress] = useState('');
    const [quotaId, setQuotaId] = useState('');
    const [admissionType, setAdmissionType] = useState('');
    const [applicationNo, setApplicationNo] = useState('');
    const genderOptions = [{ label: 'Male', value: 'male' }, { label: 'Female', value: 'female' }];
    const bloodGroupOptions = [{ label: 'A+', value: 'A+' }, { label: 'B+', value: 'B+' }];
    const nationalityOptions = [{ label: 'Indian', value: 'indian' }, { label: 'Other', value: 'other' }];
    const religionOptions = [{ label: 'Hindu', value: 'hindu' }, { label: 'Muslim', value: 'muslim' }];
    const categoryOptions = [{ label: 'General', value: 'general' }, { label: 'OBC', value: 'obc' }];
    const [section, setSection] = useState('Basic Details');
    const [academicSection, setAcademicSection] = useState('Current Details');
    const [admissionId, setAdmissionId] = useState('');
    const [previousSchoolId, setPreviousSchoolId] = useState('');
    const mediumList = [
        { label: 'English', value: 'English' },
        { label: 'Hindi', value: 'Hindi' }
    ];

    const statusList = [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' }
    ];
    const [academicYear, setAcademicYear] = useState('');
    const [grNo, setGRNo] = useState('');
    const [classList, setClassList] = useState([]);
    const [boardList, setBoardList] = useState([]);
    const [sectionList, setSectionList] = useState([]);
    const [houseList, setHouseList] = useState([]);
    const [studentStatus, setStudentStatus] = useState('');
    const [classNo, setClassNo] = useState('');
    const [classNoName, setClassNoName] = useState('');
    const [studentSection, setStudentSection] = useState('');
    const [studentSectionName, setStudentSectionName] = useState('');
    const [rollNo, setRollNo] = useState('');
    const [house, setHouse] = useState('');
    const [houseName, setHouseName] = useState('');
    const [previousSchoolName, setPreviousSchoolName] = useState('');
    const [previousMedium, setPreviousMedium] = useState('');
    const [previousBoard, setPreviousBoard] = useState('');
    const [failedInAnyClass, setFailedInAnyClass] = useState('');
    const [lastClassNo, setLastClassNo] = useState('');
    const [previousRemark, setPreviousRemark] = useState('');
    const [reasonForLeaving, setReasonForLeaving] = useState('');
    const [transferNo, setTransferNo] = useState('');
    const [leavingNo, setLeavingNo] = useState('');
    const [currentMedium, setCurrentMedium] = useState('');
    const [currentBoard, setCurrentBoard] = useState('');

    useEffect(() => {
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?._id);
        getUserDetailById();
        getPersonDetail();
        getAdmissionDetail();
        getPreviousSchoolDetail();
        GetClassList();
        GetBoardList();
        GetSectionList();
        GetHouseList();
    }, []);

    const onReferesh = () => {
        getUserDetailById();
        getPersonDetail();
        getAdmissionDetail();
        getPreviousSchoolDetail();
        GetClassList();
        GetBoardList();
        GetSectionList();
        GetHouseList();
    }

    const getUserDetailById = async (ID) => {
        try {
            let data = {
                id: props?.location?.state?.userId,
            };
            await api
                .post(APIConstant.path.getUserById, data)
                .then(async (response) => {
                    let res = await response;
                    if (res.success) {
                        Loader.hide();
                        setFullName(res?.data?.Name);
                        setMobileNumber(res?.data?.Phone);
                        setEmailAddress(res?.data?.Email);
                    }
                })
                .catch((err) => {
                    Loader.hide();
                    console.log(err);
                });
        } catch (err) {
            console.log(err);
        }
    };

    const GetClassList = async () => {
        try {
            let data = {
                UserID: securityUserID,
            }
            await api.post(APIConstant.path.getClassList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setClassList(res.data.map(x => ({
                        ...x,
                        label: x?.ClassName,
                        value: x?._id
                    })));
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const GetBoardList = async () => {
        try {
            let data = {
                UserID: securityUserID,
            }
            await api.post(APIConstant.path.getBoardList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setBoardList(res.data.map(x => ({
                        ...x,
                        label: x?.BoardName,
                        value: x?._id
                    })));
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const GetSectionList = async () => {
        try {
            let data = {
                UserID: securityUserID,
            }
            await api.post(APIConstant.path.getSectionList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setSectionList(res.data.map(x => ({
                        ...x,
                        label: x?.SectionName,
                        value: x?._id
                    })));
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const GetHouseList = async () => {
        try {
            let data = {
                UserID: securityUserID,
            }
            await api.post(APIConstant.path.getHouseList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setHouseList(res.data.map(x => ({
                        ...x,
                        label: x?.HouseName,
                        value: x?._id
                    })));
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const getPersonDetail = async (ID) => {
        try {
            let data = {
                id: props?.location?.state?.userId,
            };
            await api
                .post(APIConstant.path.GetPersonalDetailById, data)
                .then(async (response) => {
                    let res = await response;
                    if (res.success) {
                        Loader.hide();
                        setPersonalDetailId(res?.data[0]?._id);
                        setStudentId(res?.data[0]?.ProfileId);
                        setAadharNo(res?.data[0]?.AadharNo);
                        setSelectedGender(res?.data[0]?.Gender);
                        setDob(res?.data[0]?.DOB ? new Date(res?.data[0]?.DOB) : '');
                        setAge(res?.data[0]?.Age);
                        setSelectedNationality(res?.data[0]?.Nationality);
                        setSelectedBloodGroup(res?.data[0]?.BloodGroup);
                        setSelectedReligion(res?.data[0]?.Religion);
                        setSelectedCategory(res?.data[0]?.Category);
                        setLanguages(res?.data[0]?.Language.split(',') || []);
                        setAlternateMobileNumber(res?.data[0]?.AlternateContact);
                        setCurrentAddress(res?.data[0]?.CurrentAddress);
                        setPermanentAddress(res?.data[0]?.PermanentAddress);
                        setPinCode(res?.data[0]?.PinCode);
                    }
                })
                .catch((err) => {
                    Loader.hide();
                    console.log(err);
                });
        } catch (err) {
            console.log(err);
        }
    };

    const getAdmissionDetail = async (ID) => {
        try {
            let data = {
                id: props?.location?.state?.userId,
            };
            await api
                .post(APIConstant.path.GetAdmissionDetailById, data)
                .then(async (response) => {
                    let res = await response;
                    if (res.success) {
                        Loader.hide();
                        let resData = res?.data;
                        if (resData.length) {
                            setAdmissionId(resData[0]._id);
                            setDoa(resData[0]?.AdmissionDate ? new Date(resData[0]?.AdmissionDate) : '');
                            setAdmissionType(resData[0]?.AdmissionType);
                            setQuotaId(resData[0]?.QuotaId);
                            setApplicationNo(resData[0]?.ApplicationNo);
                            setCurrentMedium(resData[0]?.Medium);
                            setCurrentBoard(resData[0]?.Board);
                            setAcademicYear(resData[0]?.AcademicYear);
                            setGRNo(resData[0]?.GRNo);
                            setStudentStatus(resData[0]?.StudentStatus);
                            setClassNo(resData[0]?.ClassNo);
                            setClassNoName(resData[0]?.ClassNoName);
                            setStudentSection(resData[0]?.Section);
                            setStudentSectionName(resData[0]?.SectionName);
                            setRollNo(resData[0]?.RollNo);
                            setHouse(resData[0]?.House);
                            setHouseName(resData[0]?.HouseName);
                        }
                    }
                })
                .catch((err) => {
                    Loader.hide();
                    console.log(err);
                });
        } catch (err) {
            console.log(err);
        }
    };

    const getPreviousSchoolDetail = async (ID) => {
        try {
            let data = {
                id: props?.location?.state?.userId,
            };
            await api
                .post(APIConstant.path.GetPreviousSchoolDetailById, data)
                .then(async (response) => {
                    let res = await response;
                    if (res.success) {
                        Loader.hide();
                        let resData = res?.data;
                        if (resData.length) {
                            setPersonalDetailId(resData[0]._id);
                            setPreviousSchoolName(resData[0]?.SchoolName);
                            setPreviousMedium(resData[0]?.Medium);
                            setPreviousBoard(resData[0]?.Board);
                            setFailedInAnyClass(resData[0]?.FailedInAny);
                            setLastClassNo(resData[0]?.LastClassStudied);
                            setPreviousRemark(resData[0]?.Remark);
                            setReasonForLeaving(resData[0]?.ReasonLeaving);
                            setTransferNo(resData[0]?.TransferCrtNo);
                            setLeavingNo(resData[0]?.LeavingCrtNo);
                        }
                    }
                })
                .catch((err) => {
                    Loader.hide();
                    console.log(err);
                });
        } catch (err) {
            console.log(err);
        }
    };

    const saveDetails = async (e) => {
        await AddEditPersonalDetail();
        await AddEditAdmissionDetail();
        await AddEditPreviousSchoolDetail();
        await onReferesh();
    }

    const AddEditPersonalDetail = async (e) => {
        try {
            let data = {
                id: personalDetailId,
                UserId: props?.location?.state?.userId,
                AadharNo: aadharNo,
                Gender: selectedGender,
                DOB: moment(dob).format("YYYY-MM-DD"),
                Age: age,
                Nationality: selectedNationality,
                BloodGroup: selectedBloodGroup,
                Religion: selectedReligion,
                Category: selectedCategory,
                Language: languages.length > 1 ? languages.join(',') : languages[0],
                AlternateContact: alternateMobileNumber,
                PinCode: pinCode,
                CurrentAddress: currentAddress,
                PermanentAddress: permanentAddress,
                CreatedBy: securityUserID,
            };
            Loader.show();
            await api.post(APIConstant.path.AddPersonalDetail, data).then(async response => {
                let res = response;
                if (res.success) {
                    Toast.success({ message: 'Details Updated' });
                    Loader.hide();
                } else {
                    Toast.error({ message: res.message });
                    Loader.hide();
                }
            })
                .catch((err) => {
                    Loader.hide();
                });
        } catch (err) {
            console.log(err);
            Loader.hide();
        }
    };

    const AddEditAdmissionDetail = async (e) => {
        try {
            let data = {
                id: admissionId,
                UserId: props?.location?.state?.userId,
                ParentsId: '',
                AdmissionDate: moment(doa).format("YYYY-MM-DD"),
                AdmissionType: admissionType,
                QuotaId: quotaId,
                ApplicationNo: applicationNo,
                Medium: currentMedium,
                Board: currentBoard,
                AcademicYear: academicYear,
                GRNo: grNo,
                StudentStatus: studentStatus,
                ClassNo: classNo,
                Section: studentSection,
                RollNo: rollNo,
                House: house,
                CreatedBy: securityUserID
            };
            Loader.show();
            await api.post(APIConstant.path.AddAdmissionDetail, data).then(async response => {
                let res = response;
                if (res.success) {
                    Toast.success({ message: 'Admission details updated' });
                    Loader.hide();
                } else {
                    Toast.error({ message: res.message });
                    Loader.hide();
                }
            })
                .catch((err) => {
                    Loader.hide();
                });
        } catch (err) {
            console.log(err);
            Loader.hide();
        }
    };

    const AddEditPreviousSchoolDetail = async (e) => {
        try {
            let data = {
                id: previousSchoolId,
                UserId: props?.location?.state?.userId,
                SchoolName: previousSchoolName,
                Medium: previousMedium,
                Board: previousBoard,
                FailedInAny: failedInAnyClass,
                LastClassStudied: lastClassNo,
                Remark: previousRemark,
                ReasonLeaving: reasonForLeaving,
                TransferCrtNo: transferNo,
                LeavingCrtNo: leavingNo,
                CreatedBy: securityUserID
            };
            Loader.show();
            await api.post(APIConstant.path.AddAdmissionDetail, data).then(async response => {
                let res = response;
                if (res.success) {
                    Toast.success({ message: 'Admission details updated' });
                    Loader.hide();
                } else {
                    Toast.error({ message: res.message });
                    Loader.hide();
                }
            })
                .catch((err) => {
                    Loader.hide();
                });
        } catch (err) {
            console.log(err);
            Loader.hide();
        }
    };

    return (
        <div className="teacher-details">
            <div className="profile-header">
                <img src={userImage} alt="Profile" className="profile-pic" />
                <div className="profile-info">
                    <h2>{fullName}</h2>
                    <p>Student ID - {studentId}</p>
                    <p>GR No. - {grNo}</p>
                    <div className="transport">
                        <span>Transport Facility</span>
                        <Button label="Using" className="p-button-success p-button-sm" />
                    </div>
                </div>
                <div className="teacher-details-right">
                    <div className="designation">
                        <p>Class</p>
                        <Button label={`${classNoName}-${studentSectionName}`} className="p-button-outlined p-button-primary" />
                    </div>
                    <div className="assigned-classes">
                        <p>Roll No.</p>
                        <Button label={rollNo} className="p-button-outlined" />
                    </div>
                    <div className="subjects-taught">
                        <p>House</p>
                        <div class="status-pill">
                            <i class="fa fa-users"></i>
                            <span>{houseName}</span>
                        </div>

                    </div>
                </div>
            </div>

            <TabView>
                <TabPanel header="Personal Details">
                    <div className="form-section">
                        <div className="p-grid p-formgrid">
                            <div className="p-field-radiobutton p-col-12 p-md-4">
                                <RadioButton inputId="basicDetails" name="section" value="Basic Details" onChange={(e) => setSection(e.value)} checked={section === 'Basic Details'} />
                                <label htmlFor="basicDetails">Basic Details</label>
                            </div>
                            <div className="p-field-radiobutton p-col-12 p-md-4">
                                <RadioButton inputId="birthPlaceDetails" name="section" value="Birth Place Details" onChange={(e) => setSection(e.value)} checked={section === 'Birth Place Details'} />
                                <label htmlFor="birthPlaceDetails">Birth Place Details</label>
                            </div>
                            <div className="p-field-radiobutton p-col-12 p-md-4">
                                <RadioButton inputId="parentDetails" name="section" value="Parent Details" onChange={(e) => setSection(e.value)} checked={section === 'Parent Details'} />
                                <label htmlFor="parentDetails">Parent Details</label>
                            </div>
                        </div>

                        <div className="p-fluid">
                            {section === 'Basic Details' && (
                                <div>
                                    <div className="p-field">
                                        <label htmlFor="fullName">Full Name</label>
                                        <InputText id="fullName" value={fullName} disabled={!props?.location?.state?.isEdit} onChange={(e) => setFullName(e.target.value)} />
                                    </div>
                                    <div className="p-field">
                                        <label htmlFor="aadhar">Aadhar</label>
                                        <div className="p-inputgroup">
                                            <InputText id="aadhar" disabled={!props?.location?.state?.isEdit} onChange={(e) => setAadharNo(e.target.value)} />
                                            <Button label="Verify" disabled={!props?.location?.state?.isEdit} />
                                        </div>
                                    </div>
                                    <div className="p-field">
                                        <label htmlFor="gender">Gender</label>
                                        <Dropdown id="gender" disabled={!props?.location?.state?.isEdit} value={selectedGender} options={genderOptions} onChange={(e) => setSelectedGender(e.value)} placeholder="Select Gender" />
                                    </div>
                                    <div className="p-field">
                                        <label htmlFor="dob">Date of Birth</label>
                                        <Calendar id="dob" value={dob} onChange={(e) => setDob(e.value)} showIcon disabled={!props?.location?.state?.isEdit} />
                                    </div>
                                    <div className="p-field">
                                        <label htmlFor="age">Age</label>
                                        <InputText id="age" value={age} disabled={!props?.location?.state?.isEdit} onChange={(e) => setAge(e.target.value)} />
                                    </div>
                                    <div className="p-field">
                                        <label htmlFor="nationality">Nationality</label>
                                        <Dropdown id="nationality" value={selectedNationality} disabled={!props?.location?.state?.isEdit} options={nationalityOptions} onChange={(e) => setSelectedNationality(e.value)} placeholder="Select Nationality" />
                                    </div>
                                    <div className="p-field">
                                        <label htmlFor="bloodGroup">Blood Group</label>
                                        <Dropdown id="bloodGroup" value={selectedBloodGroup} options={bloodGroupOptions} disabled={!props?.location?.state?.isEdit} onChange={(e) => setSelectedBloodGroup(e.value)} placeholder="Select Blood Group" />
                                    </div>
                                    <div className="p-field">
                                        <label htmlFor="religion">Religion</label>
                                        <Dropdown id="religion" value={selectedReligion} options={religionOptions} disabled={!props?.location?.state?.isEdit} onChange={(e) => setSelectedReligion(e.value)} placeholder="Select Religion" />
                                    </div>
                                    <div className="p-field">
                                        <label htmlFor="category">Category</label>
                                        <Dropdown id="category" value={selectedCategory} options={categoryOptions} disabled={!props?.location?.state?.isEdit} onChange={(e) => setSelectedCategory(e.value)} placeholder="Select Category" />
                                    </div>
                                    <div className="p-field">
                                        <label htmlFor="languages">Languages Spoken</label>
                                        <Chips value={languages} onChange={(e) => setLanguages(e.value)} placeholder="Add Language" disabled={!props?.location?.state?.isEdit} />
                                    </div>
                                </div>
                            )}
                            {section === 'Birth Place Details' && (
                                <div>
                                    <div className="p-field">
                                        <label htmlFor="pinCode">Pin Code</label>
                                        <InputText id="pinCode" value={pinCode} disabled={!props?.location?.state?.isEdit} onChange={(e) => setPinCode(e.target.value)} />
                                    </div>
                                    <div className="p-field">
                                        <label htmlFor="address">Address</label>
                                        <InputTextarea id="address" value={currentAddress} type='textArea' rows={5} cols={30} onChange={(e) => setCurrentAddress(e.target.value)} disabled={!props?.location?.state?.isEdit} />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </TabPanel>
                <TabPanel header="Contact Details">
                    <div className="form-section">
                        <div className="p-fluid">
                            <div className="p-field">
                                <label htmlFor="fullName">Email Address</label>
                                <InputText id="fullName" value={emailAddress} disabled={!props?.location?.state?.isEdit} onChange={(e) => setEmailAddress(e.target.value)} />
                            </div>
                            <div className="p-field">
                                <label htmlFor="contactNo">Contact Number</label>
                                <div className="p-inputgroup">
                                    <InputText id="contactNo" type='number' value={mobileNumber} disabled={!props?.location?.state?.isEdit} onChange={(e) => setMobileNumber(e.target.value)} />
                                    <label htmlFor="emrContactNo">Emergency Contact Number</label>
                                    <InputText id="emrContactNo" type='number' value={alternateMobileNumber} onChange={(e) => setAlternateMobileNumber(e.target.value)} />
                                </div>
                            </div>
                            <div className="p-field">
                                <label htmlFor="pinCode">Pin Code</label>
                                <InputText id="pinCode" value={pinCode} disabled={!props?.location?.state?.isEdit} onChange={(e) => setPinCode(e.target.value)} />
                            </div>
                            <div className="p-field">
                                <label htmlFor="currentAddress">Current Address</label>
                                <InputTextarea id="currentAddress" value={currentAddress} type='textArea' rows={5} cols={30} onChange={(e) => setCurrentAddress(e.target.value)} disabled={!props?.location?.state?.isEdit} />
                            </div>
                            <div className="p-field">
                                <label htmlFor="permanentAddress">Permanent Address</label>
                                <InputTextarea id="permanentAddress" value={permanentAddress} rows={5} cols={30} onChange={(e) => setPermanentAddress(e.target.value)} disabled={!props?.location?.state?.isEdit} />
                            </div>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel header="Admission Details">
                    <div className="form-section">
                        <div className="p-fluid">
                            <div className="p-field">
                                <label htmlFor="doa">Admission Date</label>
                                <Calendar id="doa" value={doa} onChange={(e) => setDoa(e.value)} showIcon disabled={!props?.location?.state?.isEdit} />
                            </div>
                            <div className="p-field">
                                <label htmlFor="admissionType">Admission Type</label>
                                <InputText id="admissionType" type='number' value={admissionType} onChange={(e) => setAdmissionType(e.target.value)} disabled={!props?.location?.state?.isEdit} />
                            </div>
                            <h1>Quota Details</h1>
                            <div className="p-field">
                                <label htmlFor="quotaId">Quota</label>
                                <InputText id="quotaId" type='text' value={quotaId} onChange={(e) => setQuotaId(e.target.value)} disabled={!props?.location?.state?.isEdit} />
                            </div>
                            <div className="p-field">
                                <label htmlFor="applicationNo">Application No</label>
                                <InputText id="applicationNo" type='text' value={applicationNo} onChange={(e) => setApplicationNo(e.target.value)} disabled={!props?.location?.state?.isEdit} />
                            </div>
                            <div className="p-field">
                                <label htmlFor="currentMedium">Medium</label>
                                <Dropdown id="currentMedium" disabled={!props?.location?.state?.isEdit} value={currentMedium} options={mediumList} onChange={(e) => setCurrentMedium(e.value)} placeholder="Select Medium" />
                            </div>
                            <div className="p-field">
                                <label htmlFor="currentBoard">Board</label>
                                <Dropdown id="currentBoard" disabled={!props?.location?.state?.isEdit} value={currentBoard} options={boardList} onChange={(e) => setCurrentBoard(e.value)} placeholder="Select Board" />
                            </div>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel header="Academic Details">
                    <div className="form-section">
                        <div className="p-grid p-formgrid">
                            <div className="p-field-radiobutton p-col-12 p-md-4">
                                <RadioButton inputId="currentDetail" name="academicSection" value="Current Details" onChange={(e) => setAcademicSection(e.value)} checked={academicSection === 'Current Details'} />
                                <label htmlFor="currentDetail">Current Details</label>
                            </div>
                            <div className="p-field-radiobutton p-col-12 p-md-4">
                                <RadioButton inputId="previousDetails" name="academicSection" value="Previous Details" onChange={(e) => setAcademicSection(e.value)} checked={academicSection === 'Previous Details'} />
                                <label htmlFor="previousDetails">Previous Details</label>
                            </div>
                        </div>

                        <div className="p-fluid">
                            {academicSection === 'Current Details' && (
                                <div>
                                    <div className="p-field">
                                        <label htmlFor="academicYear">Academic Year</label>
                                        <InputText id="academicYear" value={academicYear} disabled={!props?.location?.state?.isEdit} onChange={(e) => setAcademicYear(e.target.value)} />
                                    </div>
                                    <div className="p-field">
                                        <label htmlFor="grNo">GR No.</label>
                                        <InputText id="grNo" value={grNo} disabled={!props?.location?.state?.isEdit} onChange={(e) => setGRNo(e.target.value)} />
                                    </div>
                                    <div className="p-field">
                                        <label htmlFor="studentId">StudentId</label>
                                        <InputText id="studentId" disabled={true} value={studentId} />
                                    </div>
                                    <div className="p-field">
                                        <label htmlFor="studentStatus">Student Status</label>
                                        <InputText id="studentStatus" value={studentStatus} disabled={!props?.location?.state?.isEdit} onChange={(e) => setStudentStatus(e.target.value)} />
                                    </div>
                                    <div className="p-field">
                                        <label htmlFor="classNo">Class</label>
                                        <Dropdown id="classNo" disabled={!props?.location?.state?.isEdit} value={classNo} options={classList} onChange={(e) => setClassNo(e.value)} placeholder="Select Class" />
                                    </div>
                                    <div className="p-field">
                                        <label htmlFor="section">Section</label>
                                        <Dropdown id="section" disabled={!props?.location?.state?.isEdit} value={studentSection} options={sectionList} onChange={(e) => setStudentSection(e.value)} placeholder="Select Section" />
                                    </div>
                                    <div className="p-field">
                                        <label htmlFor="rollNo">Roll No.</label>
                                        <InputText id="rollNo" value={rollNo} disabled={!props?.location?.state?.isEdit} onChange={(e) => setRollNo(e.target.value)} />
                                    </div>
                                    <div className="p-field">
                                        <label htmlFor="house">House</label>
                                        <Dropdown id="house" disabled={!props?.location?.state?.isEdit} value={house} options={houseList} onChange={(e) => setHouse(e.value)} placeholder="Select House" />
                                    </div>
                                </div>
                            )}
                            {academicSection === 'Previous Details' && (
                                <div>
                                    <div className="p-field">
                                        <label htmlFor="previousSchoolName">School Name</label>
                                        <InputText id="previousSchoolName" value={previousSchoolName} disabled={!props?.location?.state?.isEdit} onChange={(e) => setPreviousSchoolName(e.target.value)} />
                                    </div>
                                    <div className="p-field">
                                        <label htmlFor="previousMedium">Medium</label>
                                        <Dropdown id="previousMedium" disabled={!props?.location?.state?.isEdit} value={previousMedium} options={mediumList} onChange={(e) => setPreviousMedium(e.value)} placeholder="Select Medium" />
                                    </div>
                                    <div className="p-field">
                                        <label htmlFor="previousBoard">Board</label>
                                        <Dropdown id="previousBoard" disabled={!props?.location?.state?.isEdit} value={previousBoard} options={boardList} onChange={(e) => setPreviousBoard(e.value)} placeholder="Select Board" />
                                    </div>
                                    <div className="p-field">
                                        <label htmlFor="failedInAnyClass">Faild in Any Class?</label>
                                        <Dropdown id="failedInAnyClass" disabled={!props?.location?.state?.isEdit} value={failedInAnyClass} options={statusList} onChange={(e) => setFailedInAnyClass(e.value)} placeholder="Select" />
                                    </div>
                                    <div className="p-field">
                                        <label htmlFor="lastClassNo">Last Class Studied</label>
                                        <InputText type='number' id="lastClassNo" value={lastClassNo} disabled={!props?.location?.state?.isEdit} onChange={(e) => setLastClassNo(e.target.value)} />
                                    </div>
                                    <div className="p-field">
                                        <label htmlFor="remark">Remark</label>
                                        <InputText id="remark" value={previousRemark} disabled={!props?.location?.state?.isEdit} onChange={(e) => setPreviousRemark(e.target.value)} />
                                    </div>
                                    <div className="p-field">
                                        <label htmlFor="reasonForLeaving">Reason for leaving School</label>
                                        <InputText id="reasonForLeaving" value={reasonForLeaving} disabled={!props?.location?.state?.isEdit} onChange={(e) => setReasonForLeaving(e.target.value)} />
                                    </div>
                                    <div className="p-field">
                                        <label htmlFor="transferNo">Transfer Certificate No</label>
                                        <InputText id="transferNo" value={transferNo} disabled={!props?.location?.state?.isEdit} onChange={(e) => setTransferNo(e.target.value)} />
                                    </div>
                                    <div className="p-field">
                                        <label htmlFor="leavingNo">Leaving Certificate No</label>
                                        <InputText id="leavingNo" value={leavingNo} disabled={!props?.location?.state?.isEdit} onChange={(e) => setLeavingNo(e.target.value)} />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </TabPanel>
            </TabView>

            <div className="form-actions">
                {props?.location?.state?.isEdit ?
                    <Button label="Save" icon="pi pi-check" onClick={() => saveDetails()} /> : null}
                <Button label="Back" className="p-button-secondary" onClick={() => navigate('/student-master')} />
            </div>
        </div>
    );
}

export default ViewEditStudentDetails;
