import React from 'react';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import '../../../assets/css/teacherMaster.css';
import userImage from '../../../assets/images/UserImage.png';
import { useNavigate } from 'react-router-dom';

const StudentCard = ({ student }) => {
    const navigate  = useNavigate();
    return (
        <div className="teacher-card">
            <img src={student?.image ?? userImage} alt={student?.image ?? userImage} className="teacher-image" />
            <h3>{student?.Name}</h3>
            <p>{student?.Status}</p>
            {/* <div className="subjects">
                {student?.subjects?.map(subject => (
                    <Tag key={subject} value={subject} />
                ))}
            </div> */}
            <div className="actions">
                <Button label="View Profile" className="p-button-outlined p-button-primary" onClick={() => navigate('/viewEdit-student-master',{ state: { userId: student?._id, isEdit: false }} )} />
                <Button label="Edit Profile" className="p-button-outlined p-button-secondary" onClick={() => navigate('/viewEdit-student-master', { state: { userId: student?._id, isEdit:true } })} />
            </div>
        </div>
    );
}

export default StudentCard;
