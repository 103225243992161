import React, { useState,useEffect } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import StudentCard from './StudentCard';
import '../../../assets/css/teacherMaster.css';
import { useNavigate } from 'react-router-dom';
import AddStudentMasterDialog from './AddStudentMaster';
import { Dialog } from 'primereact/dialog';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';

const StudentMaster = () => {
    const navigate  = useNavigate();
    const [securityUserID, setSecurityUserID] = useState("");
    const [sortOption, setSortOption] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [visible, setVisible] = useState(false);
    const [studentsList,setStudentsList] = useState([]);

    useEffect(() => {
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?._id);
        GetStudentsList();
    }, []);

    const onCancel = () => {
        setVisible(false);
        GetStudentsList();
    }

    const GetStudentsList = async () => {
        try {
            let data = {
                UserID: securityUserID,
            }
            await api.get(APIConstant.path.getUser, data).then(async response => {
                let res = response;
                if (res.success) {
                    setStudentsList(res.data.filter((x) => x.UserType === 'Student'));
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    return (
        <div className="teacher-master">
            <div className="header">
                <h2>Student Master</h2>
                <div className="search-bar">
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} placeholder="Global Search" />
                    </span>
                    <Button icon="pi pi-plus" className="p-button-rounded p-button-text" onClick={() => setVisible(true)} />
                </div>
                
            </div>
            <div className="teacher-grid">
                {studentsList.map(student => (
                    <StudentCard key={student._id} student={student} />
                ))}
            </div>
            <Dialog
                header="Add Student Master"
                className='dialog-popup'
                visible={visible}
                onHide={() => onCancel()}
                draggable={false}
                
                position="top"
            >
                <AddStudentMasterDialog onCancel={onCancel} />

            </Dialog>
        </div>
    );
}

export default StudentMaster;
