import React, { useState, useEffect } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { RadioButton } from 'primereact/radiobutton';
import { ColorPicker } from 'primereact/colorpicker';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Divider } from 'primereact/divider';
import { FloatLabel } from 'primereact/floatlabel';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primeicons/primeicons.css';
import '../../../assets/css/idCardTemplateConfig.css';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import Toast from '../../Shared/Toast/Toast';
import { apiBase } from '../../../utils/config';

const AddEditIdCardTemplate = (props) => {
    const [securityUserID, setSecurityUserID] = useState("");
    const [templateWidth, setTemplateWidth] = useState(null);
    const [templateHeight, setTemplateHeight] = useState(null);
    const [fontTheme, setFontTheme] = useState(null);
    const [orientation, setOrientation] = useState('vertical');
    const [titleName, setTitleName] = useState('');
    const [titleNameErr, setTitleNameErr] = useState(false);
    const [titleNameErrText, setTitleNameErrText] = useState('');
    const [applySettingTo, setApplySettingTo] = useState('front');
    const [headerColor, setHeaderColor] = useState('#BD0000');
    const [footerColor, setFooterColor] = useState('#BD0000');
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedBodyOptions, setSelectedBodyOptions] = useState([]);
    const [backgroundImage, setBackgroundImage] = useState(null);
    const [sigunatureImage, setSigunatureImage] = useState(null);
    const [authorityTitle, setAuthorityTitle] = useState(null);
    const [authorityTitleList, setAuthorityTitleList] = useState([
        { label: 'Principal', value: 'Principal' },
        { label: 'Class Tacher', value: 'Class Teacher' }
    ]);
    const [isNext, setIsNext] = useState(false);
    const fontThemes = [
        { label: 'Comic Sans', value: 'Comic Sans' },
        { label: 'Arial', value: 'Arial' },
        { label: 'Times New Roman', value: 'Times New Roman' }
    ];
    const options = [
        { label: 'School Logo', value: 'schoolLogo' },
        { label: 'School Name', value: 'schoolName' },
        { label: 'School Address', value: 'schoolAddress' },
        { label: 'Contact No.', value: 'contactNo' },
        { label: 'Affiliation No.', value: 'affiliationNo' },
        { label: 'Affiliation Board', value: 'affiliationBoard' },
        { label: 'School Email', value: 'schoolEmail' }
    ];
    const bodyoptionsList = [
        { label: 'Student Profile Pic', value: 'studentProfilePic' },
        { label: 'Student Name', value: 'studentName' },
        { label: 'Academic Year', value: 'academicYear' },
        { label: 'Roll No.', value: 'rollNo' },
        { label: 'Class & Section', value: 'classSection' },
        { label: 'Mother Name', value: 'motherName' },
        { label: 'Father Name', value: 'fatherName' },
        { label: 'DOB', value: 'dob' },
        { label: 'Mother Contact No.', value: 'motherPhoneNo' },
        { label: 'Father Contact No.', value: 'fatherContactNo' },
        { label: 'Barcode', value: 'barCode' },
        { label: 'QR Code', value: 'qrCode' }
    ];

    useEffect(() => {
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?._id);
        if (props?.configId) {
            setTitleName(props?.masterData?.TitleName);
            setTitleName(props?.masterData?.TitleName);
            setApplySettingTo(props?.masterData?.ApplyThisIn);
            setTemplateWidth(props?.masterData?.TemplateWidth);
            setTemplateHeight(props?.masterData?.TemplateHeight);
            setFontTheme(props?.masterData?.FontTheme);
            setOrientation(props?.masterData?.Orientation);
            setHeaderColor(props?.masterData?.HeaderColor)
            setSelectedOptions(props?.masterData?.HeaderSection?.split(',') || []);
            setSelectedBodyOptions(props?.masterData?.BodySection?.split(',') || []);
            setFooterColor(props?.masterData?.FooterColor);
            setAuthorityTitle(props?.masterData?.AuthorityTitle);
            setBackgroundImage(props?.masterData?.BackGroundImage ? `${apiBase}/TemplateBackGround/${props?.masterData?.BackGroundImage}` : null);
            setSigunatureImage(props?.masterData?.SignImage ? `${apiBase}/TemplateSign/${props?.masterData?.SignImage}`: null);
        }
    }, []);

    const handleCheckboxChange = (e) => {
        const selectedValues = e.checked
            ? [...selectedOptions, e.value]
            : selectedOptions.filter(item => item !== e.value);
        setSelectedOptions(selectedValues);
    };

    const handleCheckBodyboxChange = (e) => {
        const selectedBodyValues = e.checked
            ? [...selectedBodyOptions, e.value]
            : selectedBodyOptions.filter(item => item !== e.value);
        setSelectedBodyOptions(selectedBodyValues);
    };

    const validate = () => {
        let formIsValid = true;
        if (CommonConfig.isEmpty(titleName)) {
            formIsValid = false;
            setTitleNameErr(true);
            setTitleNameErrText("Title Name is required");
        } else {
            setTitleNameErr(false);
            setTitleNameErrText("");
        }
        return formIsValid;
    };

    const onCancel = () => {
        props.onCancel();
    }
    const AddUpdateTemplate = async (e) => {
        if (validate(e)) {
            try {
                const formData = new FormData();
                formData.append("id", props?.masterData?._id);
                formData.append("TitleName", titleName);
                formData.append("ApplyThisIn", applySettingTo);
                formData.append("TemplateWidth", templateWidth);
                formData.append("TemplateHeight", templateHeight);
                formData.append("FontTheme", fontTheme);
                formData.append("Orientation", orientation);
                formData.append("HeaderColor", headerColor);
                formData.append("HeaderSection", selectedOptions.length > 1 ? selectedOptions.join(',') : selectedOptions[0]);
                formData.append("BodySection", selectedBodyOptions.length > 1 ? selectedBodyOptions.join(',') : selectedBodyOptions[0],);
                formData.append("FooterColor", footerColor);
                formData.append("AuthorityTitle", authorityTitle);
                formData.append("CreatedBy", securityUserID);
                debugger
                if (backgroundImage && backgroundImage.type) {
                    formData.append("BackGroundImage", backgroundImage);
                }
                if (sigunatureImage && sigunatureImage.type) {
                    formData.append("SignImage", sigunatureImage);
                }
                Loader.show();
                await api
                    .post(APIConstant.path.AddUpdateIdCardConfig, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then((response) => {
                        let res = response;
                        if (res.success) {
                            Toast.success({ message: res.message });
                            Loader.hide();
                            onCancel();
                        } else {
                            Toast.error({ message: res.message });
                            Loader.hide();
                            onCancel();
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        Toast.error({ message: err });
                        Loader.hide();
                        onCancel();
                    });
            } catch (err) {
                console.log(err);
                Toast.error({ message: err });
                Loader.hide();
                onCancel();
            }
        }
    };

    const handleBackGroundImage = (e) => {
        const selectedFile = e.files[0];
        if (selectedFile) {
            const validFormats = ['image/jpeg', 'image/png', 'image/jpg'];
            if (validFormats.includes(selectedFile.type)) {
                setBackgroundImage(selectedFile);
            } else {
                Toast.error({ message: 'Invalid file format. Please select a JPEG or PNG image.' });
            }
        }
    };

    const handleSigunatureImage = (e) => {
        const selectedFile = e.files[0];
        if (selectedFile) {
            const validFormats = ['image/jpeg', 'image/png', 'image/jpg'];
            if (validFormats.includes(selectedFile.type)) {
                setSigunatureImage(selectedFile);
            } else {
                Toast.error({ message: 'Invalid file format. Please select a JPEG or PNG image.' });
            }
        }
    };

    return (
        <div className="template-config-container">
            {!isNext ? (
                <>
                    <div className="title-settings p-grid p-align-center">
                        <h3 className="p-col-12">Title</h3>
                        <div className="p-col-6">
                            <FloatLabel>
                                <InputText
                                    id="titleName"
                                    value={titleName}
                                    onChange={(e) => setTitleName(e.target.value)}
                                />
                                <label htmlFor="titleName">Title Name</label>
                            </FloatLabel>
                        </div>
                        <div className="p-col-6">
                            <label className="p-mb-2">Apply this setting to</label>
                            <div className="p-d-flex p-ai-center">
                                <div className="p-field-radiobutton p-mr-3">
                                    <RadioButton
                                        inputId="front"
                                        name="applySettingTo"
                                        value="front"
                                        checked={applySettingTo === 'front'}
                                        onChange={(e) => setApplySettingTo(e.value)}
                                    />
                                    <label htmlFor="front">Front</label>
                                </div>
                                <div className="p-field-radiobutton">
                                    <RadioButton
                                        inputId="back"
                                        name="applySettingTo"
                                        value="back"
                                        checked={applySettingTo === 'back'}
                                        onChange={(e) => setApplySettingTo(e.value)}
                                    />
                                    <label htmlFor="back">Back</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Divider />
                    <div className="layout-settings">
                        <h3>Layout Settings</h3>
                        <div className="p-grid p-fluid">
                            <div className="p-col-6">
                                <FloatLabel>
                                    <InputNumber id="templateWidth" value={templateWidth} onValueChange={(e) => setTemplateWidth(e.value)} />
                                    <label htmlFor="templateWidth">ID Template Width (cm)</label>
                                </FloatLabel>
                            </div>
                            <div className="p-col-6">
                                <FloatLabel>
                                    <InputNumber id="templateHeight" value={templateHeight} onValueChange={(e) => setTemplateHeight(e.value)} />
                                    <label htmlFor="templateHeight">ID Template Height (cm)</label>
                                </FloatLabel>
                            </div>
                            <div className="p-col-6">
                                <FloatLabel>
                                    <Dropdown id="fontTheme" value={fontTheme} options={fontThemes} onChange={(e) => setFontTheme(e.value)} placeholder="Select a font theme" />
                                    <label htmlFor="fontTheme">Font Theme</label>
                                </FloatLabel>
                            </div>
                            <div className="p-col-6 orientation-container">
                                <label>Orientation</label>
                                <div className="orientation-radio-buttons">
                                    <RadioButton inputId="vertical" name="orientation" value="vertical" checked={orientation === 'vertical'} onChange={(e) => setOrientation(e.value)} />
                                    <label htmlFor="vertical">Vertical</label>
                                    <RadioButton inputId="horizontal" name="orientation" value="horizontal" checked={orientation === 'horizontal'} onChange={(e) => setOrientation(e.value)} />
                                    <label htmlFor="horizontal">Horizontal</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Divider />
                    <div className="header-section p-grid p-ai-center">
                        <div className="p-col-12 p-d-flex p-ai-center p-jc-between">
                            <h3 className="p-m-0">Header Section</h3>
                            <label htmlFor="headerColor">Header Color</label>
                            <div className="p-d-flex p-ai-center">
                                <ColorPicker
                                    value={headerColor}
                                    onChange={(e) => setHeaderColor(e.value)}
                                    style={{ width: '100%' }}
                                />
                                <input
                                    type="text"
                                    value={`${headerColor.toUpperCase()}`}
                                    readOnly
                                    style={{
                                        width: '100%',
                                        border: '1px solid #ccc',
                                        borderRadius: '5px',
                                        marginTop: '0.5rem',
                                        padding: '0.5rem',
                                        textAlign: 'center'
                                    }}
                                />
                            </div>
                        </div>

                        <div className="p-col-12 checkbox-group p-d-flex p-flex-wrap">
                            {options.map((option) => (
                                <div key={option.value} className="p-field-checkbox p-mr-2 p-mb-2">
                                    <Checkbox
                                        inputId={option.value}
                                        value={option.value}
                                        onChange={handleCheckboxChange}
                                        checked={selectedOptions.includes(option.value)}
                                    />
                                    <label htmlFor={option.value}>{option.label}</label>
                                </div>
                            ))}
                        </div>

                        <div className="p-col-12 p-md-4">
                            <div className="p-text-center">
                                <img
                                    src={!CommonConfig.isEmpty(backgroundImage) ? backgroundImage : "https://via.placeholder.com/150"}
                                    alt="backGroundImage"
                                    className="p-mb-3"
                                    style={{ width: '150px', height: '150px', borderRadius: '8px' }}
                                />
                                <FileUpload
                                    id="backgroundImage"
                                    mode="basic"
                                    name="backgroundImage"
                                    accept="image/*"
                                    maxFileSize={500000}
                                    chooseLabel="Select Background Image"
                                    auto={false}
                                    onSelect={(e) => handleBackGroundImage(e)}
                                />
                            </div>
                        </div>
                    </div>
                    <Button label="Next" icon="pi pi-arrow-right" onClick={(e) => setIsNext(true)} />
                </>) : (
                <>
                    <div className="header-section p-ai-center">
                        <h3 className="p-m-0">Body Section</h3>
                        <div className="p-col-12 checkbox-group p-d-flex p-flex-wrap">
                            {bodyoptionsList.map((option) => (
                                <div key={option.value} className="p-field-checkbox p-mr-2 p-mb-2">
                                    <Checkbox
                                        inputId={option.value}
                                        value={option.value}
                                        onChange={handleCheckBodyboxChange}
                                        checked={selectedBodyOptions.includes(option.value)}
                                    />
                                    <label htmlFor={option.value}>{option.label}</label>
                                </div>
                            ))}
                        </div>
                    </div>
                    <Divider />
                    <div className="header-section p-grid p-ai-center">
                        <h3 className="p-m-0">Footer Section</h3>
                        <div className="p-col-12 p-mt-3">
                            <div className="p-d-flex p-ai-center">
                                <label htmlFor="footerColor">Footer Color</label>
                                <ColorPicker
                                    value={footerColor}
                                    onChange={(e) => setFooterColor(e.value)}
                                    style={{ width: '100%' }}
                                />
                                <input
                                    type="text"
                                    value={`${footerColor.toUpperCase()}`}
                                    readOnly
                                    style={{
                                        width: '100%',
                                        border: '1px solid #ccc',
                                        borderRadius: '5px',
                                        marginTop: '0.5rem',
                                        padding: '0.5rem',
                                        textAlign: 'center'
                                    }}
                                />
                            </div>
                        </div>
                        <div className="p-col-12 p-mt-3">
                            <FloatLabel>
                                <Dropdown id="authorityTitle" value={authorityTitle} options={authorityTitleList} onChange={(e) => setAuthorityTitle(e.value)} placeholder="Select Authority Title" />
                                <label htmlFor="authorityTitle">Authority Title</label>
                            </FloatLabel>
                        </div>
                        <div className="p-col-12 p-md-4">
                            <div className="p-text-center">
                                <img
                                    src={!CommonConfig.isEmpty(sigunatureImage) ? sigunatureImage : "https://via.placeholder.com/150"}
                                    alt="sigunatureImage"
                                    className="p-mb-3"
                                    style={{ width: '150px', height: '150px', borderRadius: '8px' }}
                                />
                                <FileUpload
                                    id="signImage"
                                    mode="basic"
                                    name="signImage"
                                    accept="image/*"
                                    maxFileSize={500000}
                                    chooseLabel="Select Sigunature Image"
                                    auto={false}
                                    onSelect={(e) => handleSigunatureImage(e)}
                                />
                            </div>
                        </div>
                    </div>
                    <Button label="Back" icon="pi pi-arrow-left" onClick={(e) => setIsNext(false)} />
                    <Button label="Save" onClick={(e) => AddUpdateTemplate()} />
                </>)}
        </div>
    );
};

export default AddEditIdCardTemplate;
