
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import React, { useState, useEffect } from 'react';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import Toast from '../../Shared/Toast/Toast';
import { FloatLabel } from 'primereact/floatlabel';

const AddParentMasterDialog = (props) => {
    const [parentID, setParentID] = useState("");
    const [parentName, setParentName] = useState('');
    const [parentNameErrText, setParentNameErrText] = useState('');
    const [parentNameErr, setParentNameErr] = useState(false);
    const [securityUserID, setSecurityUserID] = useState("");
    const [selectedGender, setSelectedGender] = useState(null);
    const [selectedGenderErr, setSelectedGenderErr] = useState(false);
    const [selectedGenderErrText, setSelectedGenderErrText] = useState('');
    const genderOptions = [{ label: 'Male', value: 'male' }, { label: 'Female', value: 'female' }];
    const [childrens, setChildrens] = useState([]);
    const [childrensErr, setChildrensErr] = useState(false);
    const [childrensErrText, setChildrensErrText] = useState('');
    const [childrensList, setChildrensList] = useState([]);
    const [mobileNo, setMobileNo] = useState([]);
    const [mobileNoErr, setMobileNoErr] = useState(false);
    const [mobileNoErrText, setMobileNoErrText] = useState('');
    const [password, setPassword] = useState([]);
    const [passwordErr, setPasswordErr] = useState(false);
    const [passwordErrText, setPasswordErrText] = useState('');
    const [emailId, setEmailId] = useState('');
    const [emailIdErrText, setEmailIdErrText] = useState('');
    const [emailIdErr, setEmailIdErr] = useState(false);
    const [isPasswordSeen, setIsPasswordSeen] = useState(false);
    const [accountTypeList, setAccountTypeList] = useState([]);
    const [userPlanList, setUserPlanList] = useState([]);
    const [userTypeList, setUserTypeList] = useState([]);

    useEffect(() => {
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?._id);
        generateParentId();
        getAccountType();
        GetUserRoleList();
        GetUserPlanList();
        GetChildrensList();
    }, []);

    const generateParentId = () => {
        const prefix = 'PA';
        const randomNumber = Math.floor(100000 + Math.random() * 900000);
        const newParentId = `${prefix}${randomNumber}`;
        setParentID(newParentId);
    };

    const onCancel = () => {
        props.onCancel();
        setParentID('');
        setParentName('');
        setEmailId('');
        setMobileNo('');
    }

    const validate = () => {
        let formIsValid = true;
        if (CommonConfig.isEmpty(parentName)) {
            formIsValid = false;
            setParentNameErr(true);
            setParentNameErrText("Parent Name is required");
        } else {
            setParentNameErr(false);
            setParentNameErrText("");
        }
        if (CommonConfig.isEmpty(childrens)) {
            formIsValid = false;
            setChildrensErr(true);
            setChildrensErrText("Children is required");
        } else {
            setChildrensErr(false);
            setChildrensErrText("");
        }

        if (CommonConfig.isEmpty(mobileNo)) {
            formIsValid = false;
            setMobileNoErr(true);
            setMobileNoErrText("MobileNo is required");
        } else {
            setMobileNoErr(false);
            setMobileNoErrText("");
        }
        if (CommonConfig.isEmpty(password)) {
            formIsValid = false;
            setPasswordErr(true);
            setPasswordErrText("Password is required");
        } else {
            setPasswordErr(false);
            setPasswordErrText("");
        }
        return formIsValid;
    };

    const getAccountType = async () => {
        try {
            let data = {
                Type: "AccountType",
            };
            await api
                .post(APIConstant.path.GetStringMap, data)
                .then(async (response) => {
                    let res = response;
                    if (res.success) {
                        setAccountTypeList(
                            res.data.map((data) => ({ label: data.Name, value: data._id }))
                        );
                        Loader.hide();
                    }
                })
                .catch((err) => {
                    Loader.hide();
                    console.log(err);
                });
        } catch (err) {
            console.log("err", err);
        }
    };

    const GetUserRoleList = async (value) => {
        let params = {
            AccountType: value,
        };
        try {
            await api
                .post(APIConstant.path.GetUserRole, params)
                .then(async (response) => {
                    let res = response;
                    if (res.success) {
                        setUserTypeList(
                            res.data.map((role) => ({
                                label: role.RoleName,
                                value: role._id,
                            }))
                        );
                        Loader.hide();
                    }
                })
                .catch((err) => {
                    Loader.hide();
                    console.log(err);
                });
        } catch (err) {
            console.log(err);
        }
    };

    const GetUserPlanList = async (UserType, AccountType) => {
        try {
            var params = {
                AccountType: AccountType,
                UserType: UserType,
            };
            await api
                .post(APIConstant.path.getUserPlanList, params)
                .then((res) => {
                    if (res.success) {
                        setUserPlanList(
                            res.data.map((plan) => ({
                                ...plan,
                                label: plan.PlanName,
                                value: plan._id,
                            }))
                        );
                        Loader.hide();
                    }
                })
                .catch((err) => {
                    Loader.hide();
                    console.log(err);
                });
        } catch (err) {
            console.log(err);
        }
    };

    const AddParentMaster = async (e) => {
        if (validate(e)) {
            try {
                let data = {
                    id: '',
                    ProfileId: parentID,
                    Name: parentName,
                    Email: emailId,
                    Gender: selectedGender,
                    ParentsId: childrens.length > 1 ? childrens.join(',') : childrens[0],
                    Password: password,
                    UserType: userTypeList.find((x) => x.label === 'Parent')?.value,
                    Phone: mobileNo,
                    PlanId: userPlanList.find((plan) => plan.label === 'BasicPlan')?.value,
                    isMailVerified: 1,
                    isPhoneVerified: 1,
                    AccountType: accountTypeList.find((x) => x.label === "New Member")?.value,
                    CreatedBy: securityUserID
                };

                Loader.show();
                await api.post(APIConstant.path.AddUpdateUser, data).then(async response => {
                    let res = response;
                    if (res.success) {
                        Toast.success({ message: res.message });
                        Loader.hide();
                        onCancel();
                    } else {
                        Toast.error({ message: res.message });
                        Loader.hide();
                        onCancel();
                    }
                })
                    .catch((err) => {
                        Loader.hide();
                        onCancel();
                    });
            } catch (err) {
                console.log(err);
                Loader.hide();
                onCancel();
            }
        }
    };

    const GetChildrensList = async () => {
        try {
            let data = {
                UserID: securityUserID,
            }
            await api.get(APIConstant.path.getUser, data).then(async response => {
                let res = response;
                if (res.success) {
                    setChildrensList(res.data.filter((x) => x.UserType === 'Student')
                    .map((x) => ({
                        ...x,
                        label: x.Name,
                        value: x._id,
                    })));
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    return (
        <div className="user-container p-d-flex p-flex-column p-jc-between">
            <div>
                <div className="p-col-12 p-md-4 p-pt-2">
                    <div className="">
                        <label htmlFor="parentId">Parent ID </label>
                        <InputText
                            className="p-mt-2"
                            id="parentId"
                            type="text"
                            value={parentID}
                            disabled={true}
                        />
                    </div>
                </div>
                <div className="p-col-12 p-md-4 p-pt-2">
                    <div className="">
                        <FloatLabel>
                            <label htmlFor="clasName">Parent Name </label>
                            <InputText
                                className="p-mt-2"
                                id="parentName"
                                type="text"
                                placeholder="Parent Name"
                                value={parentName}
                                autoFocus={true}
                                onChange={(e) => setParentName(e.target.value)}
                            />
                        </FloatLabel>
                        <span className="error-msg" style={{ color: 'red' }}>
                            {parentNameErr ? parentNameErrText : null}
                        </span>
                    </div>
                </div>
                <div className="p-col-12 p-md-4 p-pt-2">
                    <div className="">
                        <label htmlFor="gender">Gender</label>
                        <Dropdown id="gender" value={selectedGender} options={genderOptions} onChange={(e) => setSelectedGender(e.value)} placeholder="Select Gender" />
                        <span className="error-msg" style={{ color: 'red' }}>
                            {selectedGenderErr ? selectedGenderErrText : null}
                        </span>
                    </div>
                </div>
                <div className="p-col-12  p-md-3 p-pt-2">
                    <div className="">
                        <div className="custom-inputs">
                            <label htmlFor="children" className="mr-2">Children</label>
                            <MultiSelect
                                id="children"
                                value={childrens}
                                options={childrensList}
                                onChange={(e) => setChildrens(e.value)}
                                placeholder="Select Childrens"
                                className="mr-2"
                                display="chip"
                            />
                            <span className="error-msg" style={{ color: 'red' }}>
                                {childrensErr ? childrensErrText : null}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="p-col-12 p-md-4 p-pt-2">
                    <div className="">
                        <FloatLabel>
                            <label htmlFor="totalCapacity">Mobile No.</label>
                            <InputText
                                className="p-mt-2"
                                id="mobileNo"
                                type="number"
                                placeholder="Mobile No"
                                maxLength={10}
                                value={mobileNo}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (value.length <= 10) {
                                      setMobileNo(value);
                                    }}
                                }
                            />
                        </FloatLabel>
                        <span className="error-msg" style={{ color: 'red' }}>
                            {mobileNoErr ? mobileNoErrText : null}
                        </span>
                    </div>
                </div>
                <div className="p-col-12 p-md-4 p-pt-2">
                    <div className="">
                        <FloatLabel>
                            <label htmlFor="emailId">Email Id</label>
                            <InputText
                                className="p-mt-2"
                                id="emailId"
                                type="email"
                                placeholder="Email Id"
                                value={emailId}
                                onChange={(e) => setEmailId(e.target.value)}
                            />
                        </FloatLabel>
                        <span className="error-msg" style={{ color: 'red' }}>
                            {emailIdErr ? emailIdErrText : null}
                        </span>
                    </div>
                </div>
                <div className="p-col-12 p-md-4 p-pt-2">
                    <div className='custom-inputs password-field'>
                        <FloatLabel>
                            <label htmlFor="password">Password </label>
                            <InputText
                                id='password'
                                type={isPasswordSeen ? 'text' : 'password'}
                                placeholder='******'
                                className='p-w-100 p-mt-2'
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <i
                                className={
                                    isPasswordSeen
                                        ? 'icon-view tailer-icon'
                                        : 'icon-view-off tailer-icon'
                                }
                                onClick={() =>
                                    setIsPasswordSeen(!isPasswordSeen)
                                }
                            ></i>
                        </FloatLabel>
                        <span className="error-msg" style={{ color: 'red' }}>
                            {passwordErr ? passwordErrText : null}
                        </span>
                    </div>
                </div>

            </div>
            <div className="card-footer">
                <Button label="Submit" severity="secondary" raised onClick={(e) => AddParentMaster()} />
                <Button className='btn-cancel' onClick={(e) => onCancel()}> cancel </Button>
            </div>
        </div>
    );
};

export default AddParentMasterDialog;
