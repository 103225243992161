import React, { useState,useEffect } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import 'primereact/resources/themes/saga-blue/theme.css'; 
import 'primereact/resources/primereact.min.css';
import { Dialog } from 'primereact/dialog';
import StudentListDialog from './StudentList';
import CommonConfig from '../../../utils/constant';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import Toast from '../../Shared/Toast/Toast';
import moment from 'moment';

const ClassConfig = (props) => {
    const [securityUserID, setSecurityUserID] = useState("");
    const [classConfigurationId, setClassConfigurationId] = useState('');
    const [studentVisible, setStudentVisible] = useState(false);
    const [classDuration, setClassDuration] = useState({ teachingFrom: null, teachingTo: null, breakFrom: null, breakTo: null });
    const [classDurationErr, setClassDurationErr] = useState({ teachingFrom: false, teachingTo: false, breakFrom: false, breakTo: false });
    const [classDurationErrText, setClassDurationErrText] = useState({ teachingFrom: '', teachingTo: '', breakFrom: '', breakTo: '' });
    const [selectedTeacher, setSelectedTeacher] = useState(null);
    const [classTeacherErr, setClassTeacherErr] = useState(false);
    const [classTeacherErrText, setClassTeacherErrText] = useState('');
    const [teachersList, setTeachersList] = useState([]);

    const subjects = [
        { id: 1, name: 'English', code: '65', primaryTeacher: 'Rishabh Patel', secondaryTeacher: 'Rishabh Patel' },
        { id: 2, name: 'Maths', code: '50', primaryTeacher: 'Rishabh Patel', secondaryTeacher: 'Rishabh Patel' }
    ];

    useEffect(() => {
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?._id);
        GetTeachersList();
        GetClassConfigurationDetail();
    }, []);

    const GetTeachersList = async () => {
        try {
            let data = {
                UserID: securityUserID,
            }
            await api.get(APIConstant.path.getUser, data).then(async response => {
                let res = response;
                if (res.success) {
                    setTeachersList(res.data.filter((x) => x.UserType === 'Teacher').map((y) => ({
                        ...y,
                        label: y.Name,
                        value: y._id
                    })));
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const GetClassConfigurationDetail = async () => {
        try {
            let data = {
                ClassId: props?.configId,
            }
            await api.post(APIConstant.path.getClassConfigurationList, data).then(async response => {
                let res = response;
                if (res.success) {
                    let resData = res.data[0];
                    setClassConfigurationId(resData?._id);
                    setClassDuration({...classDuration,teachingFrom: resData?.TeachingFrom ? new Date(resData?.TeachingFrom) : null, teachingTo: resData?.TeachingTo ? new Date(resData?.TeachingTo) : null, breakFrom: resData?.BreakFrom ? new Date(resData?.BreakFrom) : null, breakTo: resData?.BreakTo ? new Date(resData?.BreakTo) : null});
                    setSelectedTeacher(resData?.ClassTeacherId);
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const onStudentCancel =()=>{
        setStudentVisible(false);
    }

    const validate = () => {
        let formIsValid = true;
        if (CommonConfig.isEmpty(classDuration?.teachingFrom)) {
            formIsValid = false;
            setClassDurationErr({...classDurationErr ,teachingFrom: true});
            setClassDurationErrText({...classDurationErrText, teachingFrom: "Teaching From Time is required"});
        } else {
            setClassDurationErr({...classDurationErr ,teachingFrom: false});
            setClassDurationErrText({...classDurationErrText, teachingFrom: ""});
        }
        if (CommonConfig.isEmpty(classDuration?.teachingTo)) {
            formIsValid = false;
            setClassDurationErr({...classDurationErr ,teachingTo: true});
            setClassDurationErrText({...classDurationErrText, teachingTo: "Teaching To Time is required"});
        } else {
            setClassDurationErr({...classDurationErr ,teachingTo: false});
            setClassDurationErrText({...classDurationErrText, teachingTo: ""});
        }
        if (CommonConfig.isEmpty(classDuration?.breakFrom)) {
            formIsValid = false;
            setClassDurationErr({...classDurationErr ,breakFrom: true});
            setClassDurationErrText({...classDurationErrText, breakFrom: "Break From Time is required"});
        } else {
            setClassDurationErr({...classDurationErr ,breakFrom: false});
            setClassDurationErrText({...classDurationErrText, breakFrom: ""});
        }
        if (CommonConfig.isEmpty(classDuration?.breakTo)) {
            formIsValid = false;
            setClassDurationErr({...classDurationErr ,breakTo: true});
            setClassDurationErrText({...classDurationErrText, breakTo: "Break To Time is required"});
        } else {
            setClassDurationErr({...classDurationErr ,breakTo: false});
            setClassDurationErrText({...classDurationErrText, breakTo: ""});
        }
        if (CommonConfig.isEmpty(selectedTeacher)) {
            formIsValid = false;
            setClassTeacherErr(true);
            setClassTeacherErrText("Class Teacher is required");
        } else {
            setClassTeacherErr(false);
            setClassTeacherErrText("");
        }

        return formIsValid;
    };

    const AddClassConfiguration = async (e) => {
        if (validate(e)) {
            try {
                let data = {
                    id: classConfigurationId,
                    ClassId: props?.configId,
                    TeachingFrom: moment(classDuration?.teachingFrom),
                    TeachingTo: moment(classDuration?.teachingTo),
                    BreakFrom: moment(classDuration?.breakFrom),
                    BreakTo: moment(classDuration?.breakTo),
                    ClassTeacherId: selectedTeacher,
                    // SubjectsId: userTypeList.find((x) => x.label === 'Parent')?.value,
                    CreatedBy: securityUserID,
                    UpdatedBy: classConfigurationId ? securityUserID : '',
                };

                Loader.show();
                await api.post(APIConstant.path.AddUpdateClassConfiguration, data).then(async response => {
                    let res = response;
                    if (res.success) {
                        Toast.success({ message: res.message });
                        Loader.hide();
                        props.onCancel();
                    } else {
                        Toast.error({ message: res.message });
                        Loader.hide();
                        props.onCancel();
                    }
                })
                    .catch((err) => {
                        Loader.hide();
                        props.onCancel();
                    });
            } catch (err) {
                console.log(err);
                Loader.hide();
                props.onCancel();
            }
        }
    };

    return (
        <div className="p-fluid p-grid">
            {/* <div className="p-col-12">
                <h2>Class - 1 - A - English Config.</h2>
            </div> */}

            {/* Class Duration Section */}
            <div className="p-col-12 p-card p-p-3">
                <h4>Class Duration</h4>
                <div className="p-grid p-align-center">
                    <div className="p-col-3">Teaching From</div>
                    <div className="p-col-3">
                        <Calendar value={classDuration.teachingFrom} onChange={(e) => setClassDuration({ ...classDuration, teachingFrom: e.value })} timeOnly />
                        <span className="error-msg" style={{ color: 'red' }}>
                            {classDurationErr?.teachingFrom ? classDurationErrText?.teachingFrom : null}
                        </span>    
                    </div>
                    <div className="p-col-3">To</div>
                    <div className="p-col-3">
                        <Calendar value={classDuration.teachingTo} onChange={(e) => setClassDuration({ ...classDuration, teachingTo: e.value })} timeOnly />
                        <span className="error-msg" style={{ color: 'red' }}>
                            {classDurationErr?.teachingTo ? classDurationErrText?.teachingTo : null}
                        </span>
                    </div>
                </div>
                <div className="p-grid p-align-center">
                    <div className="p-col-3">Break From</div>
                    <div className="p-col-3">
                        <Calendar value={classDuration.breakFrom} onChange={(e) => setClassDuration({ ...classDuration, breakFrom: e.value })} timeOnly />
                        <span className="error-msg" style={{ color: 'red' }}>
                            {classDurationErr?.breakFrom ? classDurationErrText?.breakFrom : null}
                        </span>
                    </div>
                    <div className="p-col-3">To</div>
                    <div className="p-col-3">
                        <Calendar value={classDuration.breakTo} onChange={(e) => setClassDuration({ ...classDuration, breakTo: e.value })} timeOnly />
                        <span className="error-msg" style={{ color: 'red' }}>
                            {classDurationErr?.breakTo ? classDurationErrText?.breakTo : null}
                        </span>
                    </div>
                </div>
            </div>

            {/* Class Teacher Section */}
            <div className="p-col-12 p-card p-p-3">
                <h4>Class Teacher</h4>
                <Dropdown value={selectedTeacher} options={teachersList} onChange={(e) => setSelectedTeacher(e.value)} placeholder="Select Teacher" />
            </div>

            {/* Subject Teacher Section */}
            <div className="p-col-12 p-card p-p-3">
                <h4>Subject Teacher</h4>
                <DataTable value={subjects} paginator rows={10}>
                    <Column field="id" header="Sr No." />
                    <Column field="name" header="Subject Name" />
                    <Column field="code" header="Subject Code" />
                    <Column field="primaryTeacher" header="Primary Teacher" body={(rowData) => (
                        <Dropdown value={rowData.primaryTeacher} options={teachersList} onChange={(e) => { /* Handle change */ }} />
                    )} />
                    <Column field="secondaryTeacher" header="Secondary Teacher" body={(rowData) => (
                        <Dropdown value={rowData.secondaryTeacher} options={teachersList} onChange={(e) => { /* Handle change */ }} />
                    )} />
                </DataTable>
            </div>

            {/* Buttons */}
            <div className="p-col-12 p-text-right">
                <Button label="Next" icon="pi pi-arrow-right" className="p-button-info p-mr-2" onClick={() => setStudentVisible(true)} />
                <Button label="Save" icon="pi pi-save" className="p-button-primary" onClick={(e) => AddClassConfiguration(e)} />
            </div>

            <Dialog
                header="StudentList"
                // className='dialog-popup'
                visible={studentVisible}
                onHide={() => onStudentCancel()}
                draggable={false}
                position="top"
            >
                <StudentListDialog  onCancel={onStudentCancel}/>

            </Dialog>
        </div>
    );
}

export default ClassConfig;
