import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Chips } from 'primereact/chips';
import userImage from '../../../assets/images/UserImage.png';
import { TabView, TabPanel } from 'primereact/tabview';
import '../../../assets/css/teacherMasterDetail.css';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import { InputTextarea } from 'primereact/inputtextarea';
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import Toast from '../../Shared/Toast/Toast';
import { Navigate, useNavigate } from 'react-router-dom';
import moment from 'moment';

const ViewEditTeacherDetails = (props) => {
    const navigate = useNavigate();
    const [securityUserID, setSecurityUserID] = useState("");
    const [personalDetailId, setPersonalDetailId] = useState('');
    const [fullName, setFullName] = useState("");
    const [teacherId, setTeacherId] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [alternateMobileNumber, setAlternateMobileNumber] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [selectedGender, setSelectedGender] = useState(null);
    const [selectedBloodGroup, setSelectedBloodGroup] = useState(null);
    const [selectedNationality, setSelectedNationality] = useState(null);
    const [selectedReligion, setSelectedReligion] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [languages, setLanguages] = useState([]);
    const [aadharNo, setAadharNo] = useState('');
    const [aadharOTP, setAadharOTP] = useState('');
    const [isAadharVerify, setIsAadharVerify] = useState(false);
    const [dob, setDob] = useState(null);
    const [doj, setDoj] = useState(null);
    const [age, setAge] = useState('');
    const [pinCode, setPinCode] = useState('');
    const [currentAddress, setCurrentAddress] = useState('');
    const [permanentAddress, setPermanentAddress] = useState('');
    const [lastOrganzation, setLastOrganzation] = useState('');
    const [experience, setExperience] = useState('');
    const [position, setPosition] = useState('');
    const genderOptions = [{ label: 'Male', value: 'male' }, { label: 'Female', value: 'female' }];
    const bloodGroupOptions = [{ label: 'A+', value: 'A+' }, { label: 'B+', value: 'B+' }];
    const nationalityOptions = [{ label: 'Indian', value: 'indian' }, { label: 'Other', value: 'other' }];
    const religionOptions = [{ label: 'Hindu', value: 'hindu' }, { label: 'Muslim', value: 'muslim' }];
    const categoryOptions = [{ label: 'General', value: 'general' }, { label: 'OBC', value: 'obc' }];
    const [qualifications, setQualifications] = useState([]);

    const degreeOptions = [
        { label: "Bachelor's degree", value: "bachelor" },
        { label: "Master's degree", value: "master" },
        { label: "PhD", value: "phd" }
    ];

    const yearOptions = Array.from({ length: 50 }, (_, i) => ({ label: `${2024 - i}`, value: `${2024 - i}` }));

    useEffect(() => {
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?._id);
        getUserDetailById();
        getPersonDetail();
        getQualificationDetail();
    }, []);

    const onReferesh = () => {
        getUserDetailById();
        getPersonDetail();
        getQualificationDetail();
    }
    const addQualification = () => {
        setQualifications([...qualifications, {
            id: Date.now(),
            _id: '',
            degree: '',
            passingYear: '',
            college: '',
            university: '',
            percentage: ''
        }]);
    };

    // Remove a qualification entry
    const removeQualification = (id) => {
        setQualifications(qualifications.filter(q => q.id !== id));
    };

    // Update the qualification details
    const updateQualification = (id, field, value) => {
        const updatedQualifications = qualifications.map(q =>
            q.id === id ? { ...q, [field]: value } : q
        );
        setQualifications(updatedQualifications);
    };

    const getUserDetailById = async (ID) => {
        try {
            let data = {
                id: props?.location?.state?.userId,
            };
            await api
                .post(APIConstant.path.getUserById, data)
                .then(async (response) => {
                    let res = await response;
                    if (res.success) {
                        Loader.hide();
                        setFullName(res?.data?.Name);
                        setMobileNumber(res?.data?.Phone);
                        setEmailAddress(res?.data?.Email);
                        // setPassword(res?.data?.Password || "");
                        // setPlan(res?.data?.PlanId);
                        // setAccountType(res?.data?.AccountType);
                        // setCompanyId(res?.data?.CompanyId);
                    }
                })
                .catch((err) => {
                    Loader.hide();
                    console.log(err);
                });
        } catch (err) {
            console.log(err);
        }
    };

    const getPersonDetail = async (ID) => {
        try {
            let data = {
                id: props?.location?.state?.userId,
            };
            await api
                .post(APIConstant.path.GetPersonalDetailById, data)
                .then(async (response) => {
                    let res = await response;
                    if (res.success) {
                        Loader.hide();
                        setPersonalDetailId(res?.data[0]?._id);
                        setTeacherId(res?.data[0]?.ProfileId);
                        setAadharNo(res?.data[0]?.AadharNo);
                        setSelectedGender(res?.data[0]?.Gender);
                        setDob(new Date(res?.data[0]?.DOB) || '');
                        setAge(res?.data[0]?.Age);
                        setSelectedNationality(res?.data[0]?.Nationality);
                        setSelectedBloodGroup(res?.data[0]?.BloodGroup);
                        setSelectedReligion(res?.data[0]?.Religion);
                        setSelectedCategory(res?.data[0]?.Category);
                        setLanguages(res?.data[0]?.Language.split(',') || []);
                        setAlternateMobileNumber(res?.data[0]?.AlternateContact);
                        setCurrentAddress(res?.data[0]?.CurrentAddress);
                        setPermanentAddress(res?.data[0]?.PermanentAddress);
                        setPinCode(res?.data[0]?.PinCode);
                        setDoj(new Date(res?.data[0]?.DOJ));
                        setExperience(res?.data[0]?.JobExperience)
                        setLastOrganzation(res?.data[0]?.LastOrganzation);
                        setPosition(res?.data[0]?.LastJobPosition);
                    }
                })
                .catch((err) => {
                    Loader.hide();
                    console.log(err);
                });
        } catch (err) {
            console.log(err);
        }
    };

    const getQualificationDetail = async (ID) => {
        try {
            let data = {
                id: props?.location?.state?.userId,
            };
            await api
                .post(APIConstant.path.GetQualificationDetailById, data)
                .then(async (response) => {
                    let res = await response;
                    if (res.success) {
                        Loader.hide();
                        let resData = res?.data;
                        if (resData.length) {
                            let qualificationsData = [];
                            
                            resData.map((x) => {
                                qualificationsData = [...qualificationsData, {
                                    id: Date.now(),
                                    _id: x?._id,
                                    degree: x?.DegreeName,
                                    passingYear: x?.PassingYear,
                                    college: x?.CollegeName,
                                    university: x?.University,
                                    percentage: x?.Percentage
                                }];
                            })
                            setQualifications(qualificationsData)
                        } else {
                            setQualifications([
                                {
                                    id: Date.now(),
                                    _id: '',
                                    degree: '',
                                    passingYear: '',
                                    college: '',
                                    university: '',
                                    percentage: ''
                                }
                            ])
                        }
                    }
                })
                .catch((err) => {
                    Loader.hide();
                    console.log(err);
                });
        } catch (err) {
            console.log(err);
        }
    };

    const saveDetails = async (e) => {
        await AddEditPersonalDetail();
        await AddEditQualificationDetail();
        await onReferesh();
    }

    const AddEditPersonalDetail = async (e) => {
        try {
            let data = {
                id: personalDetailId,
                UserId: props?.location?.state?.userId,
                AadharNo: aadharNo,
                Gender: selectedGender,
                DOB: moment(dob).format("YYYY-MM-DD"),
                Age: age,
                Nationality: selectedNationality,
                BloodGroup: selectedBloodGroup,
                Religion: selectedReligion,
                Category: selectedCategory,
                Language: languages.length > 1 ? languages.join(',') : languages[0],
                AlternateContact: alternateMobileNumber,
                PinCode: pinCode,
                CurrentAddress: currentAddress,
                PermanentAddress: permanentAddress,
                DOJ: moment(doj).format("YYYY-MM-DD"),
                JobExperience: experience,
                LastOrganzation: lastOrganzation,
                LastJobPosition: position,
                CreatedBy: securityUserID
            };
            Loader.show();
            await api.post(APIConstant.path.AddPersonalDetail, data).then(async response => {
                let res = response;
                if (res.success) {
                    Toast.success({ message: 'Details Updated' });
                    Loader.hide();
                } else {
                    Toast.error({ message: res.message });
                    Loader.hide();
                }
            })
                .catch((err) => {
                    Loader.hide();
                });
        } catch (err) {
            console.log(err);
            Loader.hide();
        }
    };

    const AddEditQualificationDetail = async (e) => {
        try {
            if (qualifications) {
                qualifications.map(async (x) => {
                    let data = {
                        id: x._id,
                        UserId: props?.location?.state?.userId,
                        DegreeName: x.degree,
                        PassingYear: x.passingYear,
                        CollegeName: x.college,
                        University: x.university,
                        Percentage: x.percentage,
                        CreatedBy: securityUserID
                    };
                    Loader.show();
                    await api.post(APIConstant.path.AddQualificationDetail, data).then(async response => {
                        let res = response;
                        if (res.success) {
                            Toast.success({ message: 'Qualification details updated' });
                            Loader.hide();
                        } else {
                            Toast.error({ message: res.message });
                            Loader.hide();
                        }
                    })
                        .catch((err) => {
                            Loader.hide();
                        });
                })

            }
        } catch (err) {
            console.log(err);
            Loader.hide();
        }
    };

    return (
        <div className="teacher-details">
            <div className="profile-header">
                <img src={userImage} alt="Profile" className="profile-pic" />
                <div className="profile-info">
                    <h2>{fullName}</h2>
                    <p>Full Time</p>
                    <p>Morning Shift</p>
                    <p>Teacher ID - {teacherId}</p>
                    <div className="cv-section">
                        <span>CV - <i className="pi pi-eye"></i> <i className="pi pi-download"></i></span>
                    </div>
                    <div className="transport">
                        <span>Transport Facility</span>
                        <Button label="Using" className="p-button-success p-button-sm" />
                    </div>
                </div>
                <div className="teacher-details-right">
                    <div className="designation">
                        <p>Designation</p>
                        <Button label="Class Teacher" className="p-button-outlined p-button-primary" />
                        <Button label="Subject Teacher" className="p-button-outlined p-button-secondary" />
                    </div>
                    <div className="assigned-classes">
                        <p>Assigned Class & Section</p>
                        <Button label="1-A" className="p-button-outlined" />
                        <Button label="2-A" className="p-button-outlined" />
                        <Button label="3-A" className="p-button-outlined" />
                    </div>
                    <div className="subjects-taught">
                        <p>Subject Taught</p>
                        <Button label="Maths" className="p-button-outlined p-button-primary" />
                        <Button label="Arts" className="p-button-outlined p-button-primary" />
                        <Button label="English" className="p-button-outlined p-button-primary" />
                    </div>
                </div>
            </div>

            <TabView>
                <TabPanel header="Personal Details">
                    <div className="form-section">
                        <div className="p-fluid">
                            <div className="p-field">
                                <label htmlFor="fullName">Full Name</label>
                                <InputText id="fullName" value={fullName} disabled={!props?.location?.state?.isEdit} onChange={(e) => setFullName(e.target.value)} />
                            </div>
                            <div className="p-field">
                                <label htmlFor="aadhar">Aadhar</label>
                                <div className="p-inputgroup">
                                    <InputText id="aadhar" disabled={!props?.location?.state?.isEdit} onChange={(e) => setAadharNo(e.target.value)} />
                                    <Button label="Verify" disabled={!props?.location?.state?.isEdit} />
                                </div>
                            </div>
                            <div className="p-field">
                                <label htmlFor="gender">Gender</label>
                                <Dropdown id="gender" disabled={!props?.location?.state?.isEdit} value={selectedGender} options={genderOptions} onChange={(e) => setSelectedGender(e.value)} placeholder="Select Gender" />
                            </div>
                            <div className="p-field">
                                <label htmlFor="dob">Date of Birth</label>
                                <Calendar id="dob" value={dob} onChange={(e) => setDob(e.value)} showIcon disabled={!props?.location?.state?.isEdit} />
                            </div>
                            <div className="p-field">
                                <label htmlFor="age">Age</label>
                                <InputText id="age" value={age} disabled={!props?.location?.state?.isEdit} onChange={(e) => setAge(e.target.value)} />
                            </div>
                            <div className="p-field">
                                <label htmlFor="nationality">Nationality</label>
                                <Dropdown id="nationality" value={selectedNationality} disabled={!props?.location?.state?.isEdit} options={nationalityOptions} onChange={(e) => setSelectedNationality(e.value)} placeholder="Select Nationality" />
                            </div>
                            <div className="p-field">
                                <label htmlFor="bloodGroup">Blood Group</label>
                                <Dropdown id="bloodGroup" value={selectedBloodGroup} options={bloodGroupOptions} disabled={!props?.location?.state?.isEdit} onChange={(e) => setSelectedBloodGroup(e.value)} placeholder="Select Blood Group" />
                            </div>
                            <div className="p-field">
                                <label htmlFor="religion">Religion</label>
                                <Dropdown id="religion" value={selectedReligion} options={religionOptions} disabled={!props?.location?.state?.isEdit} onChange={(e) => setSelectedReligion(e.value)} placeholder="Select Religion" />
                            </div>
                            <div className="p-field">
                                <label htmlFor="category">Category</label>
                                <Dropdown id="category" value={selectedCategory} options={categoryOptions} disabled={!props?.location?.state?.isEdit} onChange={(e) => setSelectedCategory(e.value)} placeholder="Select Category" />
                            </div>
                            <div className="p-field">
                                <label htmlFor="languages">Languages Spoken</label>
                                <Chips value={languages} onChange={(e) => setLanguages(e.value)} placeholder="Add Language" disabled={!props?.location?.state?.isEdit} />
                            </div>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel header="Contact Details">
                    <div className="form-section">
                        <div className="p-fluid">
                            <div className="p-field">
                                <label htmlFor="fullName">Email Address</label>
                                <InputText id="fullName" value={emailAddress} disabled={!props?.location?.state?.isEdit} onChange={(e) => setEmailAddress(e.target.value)} />
                            </div>
                            <div className="p-field">
                                <label htmlFor="contactNo">Contact Number</label>
                                <div className="p-inputgroup">
                                    <InputText id="contactNo" type='number' value={mobileNumber} disabled={!props?.location?.state?.isEdit} onChange={(e) => setMobileNumber(e.target.value)} />
                                    <label htmlFor="emrContactNo">Emergency Contact Number</label>
                                    <InputText id="emrContactNo" type='number' value={alternateMobileNumber} onChange={(e) => setAlternateMobileNumber(e.target.value)} />
                                </div>
                            </div>
                            <div className="p-field">
                                <label htmlFor="pinCode">Pin Code</label>
                                <InputText id="pinCode" value={pinCode} disabled={!props?.location?.state?.isEdit} onChange={(e) => setPinCode(e.target.value)} />
                            </div>
                            <div className="p-field">
                                <label htmlFor="currentAddress">Current Address</label>
                                <InputTextarea id="currentAddress" value={currentAddress} type='textArea' rows={5} cols={30} onChange={(e) => setCurrentAddress(e.target.value)} disabled={!props?.location?.state?.isEdit} />
                            </div>
                            <div className="p-field">
                                <label htmlFor="permanentAddress">Permanent Address</label>
                                <InputTextarea id="permanentAddress" value={permanentAddress} rows={5} cols={30} onChange={(e) => setPermanentAddress(e.target.value)} disabled={!props?.location?.state?.isEdit} />
                            </div>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel header="Employment Details">
                    <div className="form-section">
                        <div className="p-fluid">
                            <div className="p-field">
                                <label htmlFor="doj">Date of Joining</label>
                                <Calendar id="doj" value={doj} onChange={(e) => setDoj(e.value)} showIcon disabled={!props?.location?.state?.isEdit}/>
                            </div>
                            <div className="p-field">
                                <label htmlFor="experience">Years of Experience</label>
                                <InputText id="experience" type='number' value={experience} onChange={(e) => setExperience(e.target.value)} disabled={!props?.location?.state?.isEdit}/>
                            </div>
                            <div className="p-field">
                                <label htmlFor="lastOrganization">Last Organization</label>
                                <InputText id="lastOrganization" type='text' value={lastOrganzation} onChange={(e) => setLastOrganzation(e.target.value)} disabled={!props?.location?.state?.isEdit}/>
                            </div>
                            <div className="p-field">
                                <label htmlFor="lastJobPosition">Last Job Position</label>
                                <InputText id="lastJobPosition" type='text' value={position} onChange={(e) => setPosition(e.target.value)} disabled={!props?.location?.state?.isEdit}/>
                            </div>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel header="Qualification Details">
                    <div className="qualification-details">
                        {qualifications.map((qualification, index) => (
                            <div key={qualification.id} className="qualification-section">
                                <h4>Qualification {index + 1}</h4>
                                <Button
                                    icon="pi pi-trash"
                                    className="p-button-rounded p-button-danger p-button-sm"
                                    disabled={!props?.location?.state?.isEdit}
                                    onClick={() => removeQualification(qualification.id)}
                                />
                                <div className="p-grid">
                                    <div className="p-col-6">
                                        <div className="p-field">
                                            <label htmlFor={`degree${qualification.id}`}>Degree</label>
                                            <InputText
                                                id={`degree${qualification.id}`}
                                                value={qualification.degree}
                                                disabled={!props?.location?.state?.isEdit}
                                                onChange={(e) => updateQualification(qualification.id, 'degree', e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="p-col-6">
                                        <div className="p-field">
                                            <label htmlFor={`passingYear${qualification.id}`}>Passing Year</label>
                                            <Dropdown
                                                id={`passingYear${qualification.id}`}
                                                value={qualification.passingYear}
                                                options={yearOptions}
                                                disabled={!props?.location?.state?.isEdit}
                                                onChange={(e) => updateQualification(qualification.id, 'passingYear', e.value)}
                                                placeholder="Select Year"
                                            />
                                        </div>
                                    </div>
                                    <div className="p-col-6">
                                        <div className="p-field">
                                            <label htmlFor={`college${qualification.id}`}>College</label>
                                            <InputText
                                                id={`college${qualification.id}`}
                                                value={qualification.college}
                                                disabled={!props?.location?.state?.isEdit}
                                                onChange={(e) => updateQualification(qualification.id, 'college', e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="p-col-6">
                                        <div className="p-field">
                                            <label htmlFor={`university${qualification.id}`}>University</label>
                                            <InputText
                                                id={`university${qualification.id}`}
                                                value={qualification.university}
                                                disabled={!props?.location?.state?.isEdit}
                                                onChange={(e) => updateQualification(qualification.id, 'university', e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="p-col-6">
                                        <div className="p-field">
                                            <label htmlFor={`percentage${qualification.id}`}>Percentage</label>
                                            <InputText
                                                id={`percentage${qualification.id}`}
                                                value={qualification.percentage}
                                                disabled={!props?.location?.state?.isEdit}
                                                onChange={(e) => updateQualification(qualification.id, 'percentage', e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {props?.location?.state?.isEdit ? 
                        <div className="add-qualification">
                            <Button label="Add Qualification" icon="pi pi-plus" className="p-button-rounded p-button-primary" onClick={addQualification} />
                        </div> : null}
                    </div>
                </TabPanel>
            </TabView>

            <div className="form-actions">
                {props?.location?.state?.isEdit ? 
                <Button label="Save" icon="pi pi-check" onClick={() => saveDetails()} /> : null}
                <Button label="Back" className="p-button-secondary" onClick={() => navigate('/teacher-master')} />
            </div>
        </div>
    );
}

export default ViewEditTeacherDetails;
