//=============PATH=================//
import { apiBase } from './config';
const SERVER_PATH = apiBase;
export default class APIConstant {
  static path = {

    // Auth API

    login: `${SERVER_PATH}/authentication/loginUser`,
    logout: `${SERVER_PATH}/authentication/logoutUser`,

    // Dashboard API
    getDahboardData: `${SERVER_PATH}/dashboard/getDahboardData`,
    getAdminDashboardUser: `${SERVER_PATH}/dashboard/getAdminDashboardUser`,
    getAdminDashboardUserList: `${SERVER_PATH}/dashboard/getAdminDashboardUserList`,

    //Sidebar API
    getUserMenu: `${SERVER_PATH}/menu/getMenuItems`,
    getUserType: `${SERVER_PATH}/useraccess/getUserType`,
    getMenuMaster: `${SERVER_PATH}/useraccess/getMenuMaster`,
    addUpdateUserType: `${SERVER_PATH}/useraccess/addUpdateUserType`,
    getUserTypeByID: `${SERVER_PATH}/useraccess/getUserTypeByID`,
    getUserWiseAccessList: `${SERVER_PATH}/userAccess/getUserAccessList`,


    // Add plan 
    getUserPlanList: `${SERVER_PATH}/userPlan/getUserPlanList`,

    //  user 
    AddUpdateUser: `${SERVER_PATH}/authentication/addUsers`,
    getUser: `${SERVER_PATH}/authentication/getUsers`,
    getUserById: `${SERVER_PATH}/authentication/getUserById`,


    GetStringMap: `${SERVER_PATH}/stringMap/getStringMapList`,
    GetUserRole: `${SERVER_PATH}/userRole/GetUserRoleList`,

    //Class Master
    AddUpdateClassMaster: `${SERVER_PATH}/classMaster/addClassDetail`,
    getClassList: `${SERVER_PATH}/classMaster/getClassDetailList`,

    //Section Master
    AddUpdateSectionMaster: `${SERVER_PATH}/sectionMaster/addSectionDetail`,
    getSectionList: `${SERVER_PATH}/sectionMaster/getSectionDetailList`,

    //Teacher Master
    AddPersonalDetail: `${SERVER_PATH}/personalDetail/addPersonalDetail`,
    GetPersonalDetailById: `${SERVER_PATH}/personalDetail/getPersonalDetailById`,
    AddQualificationDetail: `${SERVER_PATH}/qualificationDetail/addQualificationDetail`,
    GetQualificationDetailById: `${SERVER_PATH}/qualificationDetail/getQualificationDetailById`,

    // Student Master
    AddAdmissionDetail: `${SERVER_PATH}/admissionDetail/addAdmissionDetail`,
    GetAdmissionDetailById: `${SERVER_PATH}/admissionDetail/getAdmissionDetailById`,
    AddPreviousSchoolDetail: `${SERVER_PATH}/previousSchoolDetail/addPreviousSchoolDetail`,
    GetPreviousSchoolDetailById: `${SERVER_PATH}/previousSchoolDetail/getPreviousSchoolDetailById`,

    //House Master
    AddUpdateHouseMaster: `${SERVER_PATH}/houseMaster/addHouseDetail`,
    getHouseList: `${SERVER_PATH}/houseMaster/getHouseDetailList`,

    //Class Configuration
    AddUpdateClassConfiguration: `${SERVER_PATH}/classConfiguration/addClassConfiguration`,
    getClassConfigurationList: `${SERVER_PATH}/classConfiguration/getClassConfigurationList`,
    getStudentDetailList: `${SERVER_PATH}/classConfiguration/getStudentDetailList`,

    //Board Master
    AddUpdateBoardMaster: `${SERVER_PATH}/boardMaster/addBoardDetail`,
    getBoardList: `${SERVER_PATH}/boardMaster/getBoardDetailList`,

    //Id Card Configuration
    AddUpdateIdCardConfig: `${SERVER_PATH}/idCardConfiguration/addIdCardConfiguration`,
    GetIdCardConfigurationList: `${SERVER_PATH}/idCardConfiguration/getIdCardConfigurationList`,

    //School Detail
    AddSchoolDetail: `${SERVER_PATH}/schoolDetail/addSchoolDetail`,
    GetSchoolDetailById: `${SERVER_PATH}/schoolDetail/getSchoolDetailById`,
  };
}
