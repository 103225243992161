
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import React, { useState, useEffect } from 'react';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import Toast from '../../Shared/Toast/Toast';
import { FloatLabel } from 'primereact/floatlabel';

const AddEditBoardMasterDialog = (props) => {
    const [boardName, setBoardName] = useState('');
    const [securityUserID, setSecurityUserID] = useState("");
    const [boardNameErrText, setBoardNameErrText] = useState('');
    const [boardNameErr, setBoardNameErr] = useState(false);
    // const [color, setColor] = useState('#8D0000');
    // const [colorErr, setColorErr] = useState(false);
    // const [colorErrText, setColorErrText] = useState('');
    useEffect(() => {
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?._id);
        if (props.ID) {
            setBoardName(props?.masterData?.BoardName);
            // setColor(props?.masterData?.ColorCode);
        }

    }, []);

    const onCancel = () => {
        props.onCancel();
        setBoardName('');
        // setColor('');
    }

    const validate = () => {
        let formIsValid = true;
        if (CommonConfig.isEmpty(boardName)) {
            formIsValid = false;
            setBoardNameErr(true);
            setBoardNameErrText("Board Name is required");
        } else {
            setBoardNameErr(false);
            setBoardNameErrText("");
        }
        return formIsValid;
    };

    const AddEditBoardMaster = async (e) => {
        if (validate(e)) {
            try {
                Loader.show();
                let data = {
                    id: props.ID,
                    BoardName: boardName,
                    // ColorCode: color,
                    CreatedBy: securityUserID,
                };

                await api
                    .post(APIConstant.path.AddUpdateBoardMaster, data)
                    .then((response) => {
                        let res = response;
                        if (res.success) {
                            Toast.success({ message: res.message });
                            Loader.hide();
                            onCancel();
                        } else {
                            Toast.error({ message: res.message });
                            Loader.hide();
                            onCancel();
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        Loader.hide();
                        onCancel();
                    });
            } catch (err) {
                console.log(err);
                Loader.hide();
                onCancel();
            }
        }
    };

    return (
        <div className="user-container p-d-flex p-flex-column p-jc-between">
            <div>
                <div className="p-col-12 p-md-4 p-pt-2">
                    <div className="">
                        <FloatLabel>
                            <label htmlFor="boardName">Board Name </label>
                            <InputText
                                className="p-mt-2"
                                id="boardName"
                                type="text"
                                placeholder="Board Name"
                                value={boardName}
                                autoFocus={true}
                                onChange={(e) => setBoardName(e.target.value)}
                            />
                        </FloatLabel>
                        <span className="error-msg" style={{ color: 'red' }}>
                            {boardNameErr ? boardNameErrText : null}
                        </span>
                    </div>
                </div>

            </div>
            <div className="card-footer">
                <Button label="Submit" severity="secondary" raised onClick={(e) => AddEditBoardMaster()} />
                <Button className='btn-cancel' onClick={(e) => onCancel()}> cancel </Button>
            </div>
        </div>
    );
};

export default AddEditBoardMasterDialog;
