import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import React, { useState, useEffect } from 'react';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import Toast from '../../Shared/Toast/Toast';
import { Dropdown } from 'primereact/dropdown';
import { FloatLabel } from 'primereact/floatlabel';
import { MultiSelect } from 'primereact/multiselect';

const AddStudentMasterDialog = (props) => {
  const [securityUserID, setSecurityUserID] = useState("");
  const [classList, setClassList] = useState('');
  const [studentID, setStudentID] = useState("");
  const [studentName, setStudentName] = useState('');
  const [studentNameErrText, setStudentNameErrText] = useState('');
  const [studentNameErr, setStudentNameErr] = useState(false);
  const [className, setClassName] = useState('');
  const [classNameErrText, setClassNameErrText] = useState('');
  const [classNameErr, setClassNameErr] = useState(false);
  const [phoneNo, setPhoneNo] = useState('');
  const [phoneNoErrText, setPhoneNoErrText] = useState('');
  const [phoneNoErr, setPhoneNoErr] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordErrText, setPasswordErrText] = useState('');
  const [passwordErr, setPasswordErr] = useState(false);
  const [isPasswordSeen, setIsPasswordSeen] = useState(false);
  const [accountTypeList, setAccountTypeList] = useState([]);
  const [userPlanList, setUserPlanList] = useState([]);
  const [userTypeList, setUserTypeList] = useState([]);
  const [grNo, setGRNo] = useState('');
  const [grNoErrText, setGRNoErrText] = useState('');
  const [grNoErr, setGRNoErr] = useState(false);
  const [emailId, setEmailId] = useState('');
  const [emailIdErrText, setEmailIdErrText] = useState('');
  const [emailIdErr, setEmailIdErr] = useState(false);
  const [selectedGender, setSelectedGender] = useState(null);
  const [selectedGenderErr, setSelectedGenderErr] = useState(false);
  const [selectedGenderErrText, setSelectedGenderErrText] = useState('');
  const genderOptions = [{ label: 'Male', value: 'male' }, { label: 'Female', value: 'female' }];
  const [parents, setParents] = useState([]);
  const [parentList, setParentList] = useState([]);

  useEffect(() => {
    const loginData = CommonConfig.loginData();
    setSecurityUserID(loginData?._id);
    generateStudentId();
    getAccountType();
    GetUserRoleList();
    GetUserPlanList();
    GetClassList();
    GetParentList();
  }, []);

  const generateStudentId = () => {
    const prefix = 'ST';
    const randomNumber = Math.floor(100000 + Math.random() * 900000);
    const newStudentId = `${prefix}${randomNumber}`;
    setStudentID(newStudentId);
  };

  const onCancel = () => {
    props.onCancel();
    setStudentName('');
    setClassName('');
    setEmailId('');
    setStudentID('');
    setPhoneNo('');
    setPassword('');
    setSelectedGender('');
  }

  const validate = () => {
    let formIsValid = true;
    if (CommonConfig.isEmpty(studentName)) {
      formIsValid = false;
      setStudentNameErr(true);
      setStudentNameErrText("Student Name is required");
    } else {
      setStudentNameErr(false);
      setStudentNameErrText("");
    }
    if (CommonConfig.isEmpty(className)) {
      formIsValid = false;
      setClassNameErr(true);
      setClassNameErrText("ClassName is required");
    } else {
      setClassNameErr(false);
      setClassNameErrText("");
    }
    if (CommonConfig.isEmpty(phoneNo)) {
      formIsValid = false;
      setPhoneNoErr(true);
      setPhoneNoErrText("PhoneNo is required");
    } else {
      setPhoneNoErr(false);
      setPhoneNoErrText("");
    }
    if (CommonConfig.isEmpty(password)) {
      formIsValid = false;
      setPasswordErr(true);
      setPasswordErrText("Password is required");
    } else {
      setPasswordErr(false);
      setPasswordErrText("");
    }
    if (CommonConfig.isEmpty(selectedGender)) {
      formIsValid = false;
      setSelectedGenderErr(true);
      setSelectedGenderErrText("Gender is required");
    } else {
      setSelectedGenderErr(false);
      setSelectedGenderErrText("");
    }
    return formIsValid;
  };

  const getAccountType = async () => {
    try {
      let data = {
        Type: "AccountType",
      };
      await api
        .post(APIConstant.path.GetStringMap, data)
        .then(async (response) => {
          let res = response;
          if (res.success) {
            setAccountTypeList(
              res.data.map((data) => ({ label: data.Name, value: data._id }))
            );
            Loader.hide();
          }
        })
        .catch((err) => {
          Loader.hide();
          console.log(err);
        });
    } catch (err) {
      console.log("err", err);
    }
  };

  const GetClassList = async () => {
    try {
        let data = {
            UserID: securityUserID,
        }
        await api.post(APIConstant.path.getClassList, data).then(async response => {
            let res = response;
            if (res.success) {
                setClassList(res.data.map(x => ({
                    ...x,
                    label: x?.ClassName,
                    value: x?._id
                })));
                Loader.hide();
            }
        }).catch(err => {
            Loader.hide();
            console.log(err);
        });
    } catch (err) { console.log(err); }
}

const GetParentList = async () => {
  try {
      let data = {
          UserID: securityUserID,
      }
      await api.get(APIConstant.path.getUser, data).then(async response => {
          let res = response;
          if (res.success) {
              setParentList(res.data.filter((x) => x.UserType === 'Parent').map(x => ({
                label: x?.Name,
                value: x?._id
              })));
              Loader.hide();
          }
      }).catch(err => {
          Loader.hide();
          console.log(err);
      });
  } catch (err) { console.log(err); }
}

  const GetUserRoleList = async (value) => {
    let params = {
      AccountType: value,
    };
    try {
      await api
        .post(APIConstant.path.GetUserRole, params)
        .then(async (response) => {
          let res = response;
          if (res.success) {
            setUserTypeList(
              res.data.map((role) => ({
                label: role.RoleName,
                value: role._id,
              }))
            );
            Loader.hide();
          }
        })
        .catch((err) => {
          Loader.hide();
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const GetUserPlanList = async (UserType, AccountType) => {
    try {
      var params = {
        AccountType: AccountType,
        UserType: UserType,
      };
      await api
        .post(APIConstant.path.getUserPlanList, params)
        .then((res) => {
          if (res.success) {
            setUserPlanList(
              res.data.map((plan) => ({
                ...plan,
                label: plan.PlanName,
                value: plan._id,
              }))
            );
            Loader.hide();
          }
        })
        .catch((err) => {
          Loader.hide();
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const AddStudentMaster = async (e) => {
    if (validate(e)) {
      try {
        let data = {
          id: '',
          ProfileId: studentID,
          Name: studentName,
          Email: emailId,
          ClassName: className,
          Gender: selectedGender,
          ParentsId: parents.length > 1 ? parents.join(',') : parents[0],
          Password: password,
          UserType: userTypeList.find((x) => x.label === 'Student')?.value,
          Phone: phoneNo,
          PlanId: userPlanList.find((plan) => plan.label === 'BasicPlan')?.value,
          isMailVerified: 1,
          isPhoneVerified: 1,
          AccountType: accountTypeList.find((x) => x.label === "New Member")?.value,
          CreatedBy: securityUserID
          //   CompanyId: companyId,
        };

        Loader.show();
        await api.post(APIConstant.path.AddUpdateUser, data).then(async response => {
          let res = response;
          if (res.success) {
            Toast.success({ message: res.message });
            Loader.hide();
            onCancel();
          } else {
            Toast.error({ message: res.message });
            Loader.hide();
            onCancel();
          }
        })
          .catch((err) => {
            Loader.hide();
            onCancel();
          });
      } catch (err) {
        console.log(err);
        Loader.hide();
        onCancel();
      }
    }
  };

  return (
    <div className="user-container p-d-flex p-flex-column p-jc-between">
      <div>
        <div className="p-col-12 p-md-4 p-pt-2">
          <div className="">
            <label htmlFor="clasName">Student ID </label>
            <InputText
              className="p-mt-2"
              id="studentName"
              type="text"
              value={studentID}
              disabled={true}
            />
          </div>
        </div>
        <div className="p-col-12 p-md-4 p-pt-2">
          <div className="">
            <FloatLabel>
              <label htmlFor="grNo">GR No.</label>
              <InputText
                className="p-mt-2"
                id="grNo"
                type="text"
                placeholder="GR No"
                value={grNo}
                autoFocus={true}
                onChange={(e) => setGRNo(e.target.value)}
              />
            </FloatLabel>
            <span className="error-msg" style={{ color: 'red' }}>
              {grNoErr ? grNoErrText : null}
            </span>
          </div>
        </div>
        <div className="p-col-12 p-md-4 p-pt-2">
          <div className="">
            <FloatLabel>
              <label htmlFor="studentName">Full Name</label>
              <InputText
                className="p-mt-2"
                id="studentName"
                type="text"
                placeholder="Student Name"
                value={studentName}
                autoFocus={true}
                onChange={(e) => setStudentName(e.target.value)}
              />
            </FloatLabel>
            <span className="error-msg" style={{ color: 'red' }}>
              {studentNameErr ? studentNameErrText : null}
            </span>
          </div>
        </div>
        <div className="p-col-12 p-md-4 p-pt-2">
          <div className="">
              <label htmlFor="className">Class </label>
              <Dropdown id="className" value={className} options={classList} onChange={(e) => setClassName(e.value)} placeholder="Select Class" />
            <span className="error-msg" style={{ color: 'red' }}>
              {classNameErr ? classNameErrText : null}
            </span>
          </div>
        </div>
        <div className="p-col-12 p-md-4 p-pt-2">
          <div className="">
            <label htmlFor="gender">Gender</label>
            <Dropdown id="gender" value={selectedGender} options={genderOptions} onChange={(e) => setSelectedGender(e.value)} placeholder="Select Gender" />
            <span className="error-msg" style={{ color: 'red' }}>
              {selectedGenderErr ? selectedGenderErrText : null}
            </span>
          </div>
        </div>
        <div className="p-col-12  p-md-3 p-pt-2">
          <div className="">
            <div className="custom-inputs">
              <label htmlFor="parents" className="mr-2">Parents</label>
              <MultiSelect
                id="parents"
                value={parents}
                options={parentList}
                onChange={(e) => setParents(e.value)}
                placeholder="Select Parents"
                className="mr-2"
                filter
                display="chip"
              />
              
            </div>
          </div>
        </div>
        <div className="p-col-12 p-md-4 p-pt-2">
          <div className="">
            <FloatLabel>
              <label htmlFor="selectedGender">EmailId </label>
              <InputText
                className="p-mt-2"
                id="emailId"
                type="email"
                placeholder="EmailId"
                value={emailId}
                autoFocus={true}
                onChange={(e) => setEmailId(e.target.value)}
              />
            </FloatLabel>
            <span className="error-msg" style={{ color: 'red' }}>
              {emailIdErr ? emailIdErrText : null}
            </span>
          </div>
        </div>
        <div className="p-col-12 p-md-4 p-pt-2">
          <div className="">
            <FloatLabel>
              <label htmlFor="phoneNo">Phone No </label>
              <InputText
                className="p-mt-2"
                id="phoneNo"
                type="number"
                placeholder="PhoneNo"
                value={phoneNo}
                onChange={(e) => setPhoneNo(e.target.value)}
              />
            </FloatLabel>
            <span className="error-msg" style={{ color: 'red' }}>
              {phoneNoErr ? phoneNoErrText : null}
            </span>
          </div>
        </div>
        <div className="p-col-12 p-md-4 p-pt-2">
          <div className='custom-inputs password-field'>
            <FloatLabel>
              <label htmlFor="password">Password </label>
              <InputText
                id='password'
                type={isPasswordSeen ? 'text' : 'password'}
                placeholder='******'
                className='p-w-100 p-mt-2'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <i
                className={
                  isPasswordSeen
                    ? 'icon-view tailer-icon'
                    : 'icon-view-off tailer-icon'
                }
                onClick={() =>
                  setIsPasswordSeen(!isPasswordSeen)
                }
              ></i>
            </FloatLabel>
            <span className="error-msg" style={{ color: 'red' }}>
              {passwordErr ? passwordErrText : null}
            </span>
          </div>
        </div>

      </div>
      <div className="card-footer">
        <Button label="Submit" severity="secondary" raised onClick={(e) => AddStudentMaster()} />
        <Button className='btn-cancel' onClick={(e) => onCancel()}> cancel </Button>
      </div>
    </div>
  );
};

export default AddStudentMasterDialog;
