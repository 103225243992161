import Login from './components/Login/Login.js';
import Dashboard from './components/Dashboard/Dashboard.js';
import Error404 from './components/Shared/Error404/Error404.js';
import ClassMaster from './components/Masters/ClassMaster/ClassMaster.js';
import SectionMaster from './components/Masters/SectionMaster/SectionMaster.js';
import TeacherMaster from './components/Masters/TeacherMaster/TeacherMaster.js';
import ViewEditTeacherDetails from './components/Masters/TeacherMaster/ViewEditTeacherMaster.js';
import StudentMaster from './components/Masters/StudentMaster/StudentMaster.js';
import ViewEditStudentDetails from './components/Masters/StudentMaster/ViewEditStudentMaster.js';
import HouseMaster from './components/Masters/HouseMaster/HouseMaster.js';
import ParentMaster from './components/Masters/ParentMaster/ParentMaster.js';
import ViewEditParentDetails from './components/Masters/ParentMaster/ViewEditParentMaster.js';
import ClassConfiguration from './components/Configuration/Class/Class.js';
import BoardMaster from './components/Masters/BoardMaster/BoardMaster.js';
import IdCardTemplateConfiguration from './components/Configuration/IdCardTemplate/IdCardTemplate.js';
import EditProfile from './components/Profile/EditProfile.js';
import IdCardMaster from './components/Masters/IdCardMaster/IdCardMaster.js';

var dashRoutes = [
    {
        path: "/",
        name: "Login",
        component: Login,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | Login'
        },
        isPreventedRoute: true
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | Login'
        },
        isPreventedRoute: true
    },
    // {
    //     path: "/forgot-password",
    //     name: "ForgotPassword",
    //     component: ForgotPassword,
    //     icon: "",
    //     invisible: false,
    //     meta: {
    //         title: 'SCLERP | Forgot Password'
    //     },
    //     isPreventedRoute: true
    // },
    // {
    //     path: "/reset-password",
    //     name: "ResetPassword",
    //     component: ResetPassword,
    //     icon: "",
    //     invisible: false,
    //     meta: {
    //         title: 'SCLERP | Reset Password'
    //     },
    //     isPreventedRoute: true
    // },
    {
        path: "/404Error",
        name: "Error404",
        component: Error404,
        icon: "",
        invisible: false,
        meta: {
            title: 'Page not found'
        },
        isShared: true
    },

    /** Add all protected routing (requires session) here */
    {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | Dashboard'
        },
        isPreventedRoute: false
    },
    {
        path: "/edit-profile",
        name: "EditProfile",
        component: EditProfile,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | Edit Profile'
        },
        isPreventedRoute: false
    },
    {
        path: "/class-master",
        name: "ClassMaster",
        component: ClassMaster,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | ClassMaster'
        },
        isPreventedRoute: false
    },
    {
        path: "/section-master",
        name: "SectionMaster",
        component: SectionMaster,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | SectionMaster'
        },
        isPreventedRoute: false
    },
    {
        path: "/teacher-master",
        name: "TeacherMaster",
        component: TeacherMaster,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | TeacherMaster'
        },
        isPreventedRoute: false
    },
    {
        path: "/viewEdit-teacher-master",
        name: "ViewEditTeacherMaster",
        component: ViewEditTeacherDetails,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | ViewEditTeacherDetails'
        },
        isPreventedRoute: false
    },
    {
        path: "/student-master",
        name: "StudentMaster",
        component: StudentMaster,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | StudentMaster'
        },
        isPreventedRoute: false
    },
    {
        path: "/viewEdit-student-master",
        name: "ViewEditStudentMaster",
        component: ViewEditStudentDetails,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | ViewEditStudentDetails'
        },
        isPreventedRoute: false
    },
    {
        path: "/house-master",
        name: "HouseMaster",
        component: HouseMaster,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | HouseMaster'
        },
        isPreventedRoute: false
    },
    {
        path: "/parent-master",
        name: "ParentMaster",
        component: ParentMaster,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | ParentMaster'
        },
        isPreventedRoute: false
    },
    {
        path: "/viewEdit-parent-master",
        name: "ViewEditParentMaster",
        component: ViewEditParentDetails,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | ViewEditParentMaster'
        },
        isPreventedRoute: false
    },
    {
        path: "/class-configuration",
        name: "ClassConfiguration",
        component: ClassConfiguration,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | ClassConfiguration'
        },
        isPreventedRoute: false
    },
    {
        path: "/board-master",
        name: "BoardMaster",
        component: BoardMaster,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | BoardMaster'
        },
        isPreventedRoute: false
    },
    {
        path: "/id-card-template-configuration",
        name: "IdCardTemplateConfiguration",
        component: IdCardTemplateConfiguration,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | IdCardTemplateConfiguration'
        },
        isPreventedRoute: false
    },
    {
        path: "/id-card-generator",
        name: "IdCardGenerator",
        component: IdCardMaster,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | IdCardGenerator'
        },
        isPreventedRoute: false
    },

]

export default dashRoutes;