import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { FilterMatchMode } from 'primereact/api';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import GenerateIdCardDialog from './GenerateIdCard';
import { MultiSelect } from 'primereact/multiselect';
// import AddClassMasterDialog from './AddClassMaster';


const IdCardMaster = () => {

    const [classList, setClassList] = useState([]);
    const [studentsList, setStudentsList] = useState([]);
    const [masterData, setMasterData] = useState([]);
    const [classId, setClassId] = useState([]);
    const [visible, setVisible] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [classSectionName, setClassSectionName] = useState('');
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        board: { value: null, matchMode: FilterMatchMode.CONTAINS },
        medium: { value: null, matchMode: FilterMatchMode.CONTAINS },
        sections: { value: null, matchMode: FilterMatchMode.EQUALS },
        students: { value: null, matchMode: FilterMatchMode.EQUALS },
        totalCapacity: { value: null, matchMode: FilterMatchMode.EQUALS }
    });

    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [securityUserID, setSecurityUserID] = useState("");
    const [rows, setRows] = useState(10);
    const [first, setFirst] = useState(0);

    useEffect(() => {
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?._id);
        GetClassList();
    }, []);

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const onPageChange = (e) => {
        setFirst(e.first);
        setRows(e.rows);
    };

    const GetClassList = async () => {
        try {
            let data = {
                UserID: securityUserID,
            }
            await api.post(APIConstant.path.getClassList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setClassList(res.data.map(x => ({
                        ...x,
                        label: `${x?.ClassName}-${x?.SectionFullName.charAt(0)}-${x?.Medium}-${x?.Board}`,
                        value: x?._id
                    })));
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const renderColumnFilter = (field, filterMatchMode) => {
        return (
            <InputText
                value={filters[field]?.value || ''}
                onChange={(e) => {
                    let _filters = { ...filters };
                    _filters[field] = { value: e.target.value, matchMode: filterMatchMode };
                    setFilters(_filters);
                }}
                placeholder={`Search by ${field}`}
            />
        );
    };

    const onCancel = () => {
        setVisible(false);
    }
    
    const onChangeClass = (e) => {
        const selectedClassIds = e.value;
        setClassId(selectedClassIds);
    
        let updatedStudentsList = [];
        let classSectionNames = [];
    
        selectedClassIds.forEach((classId) => {
            let classData = classList.find((x) => x.value === classId);
            if (classData) {
                classSectionNames.push(`${classData.ClassName}-${classData.SectionName?.charAt(0)}`);
                if (classData.Users) {
                    updatedStudentsList = [...updatedStudentsList, ...classData.Users];
                }
            }
        });
    
        setClassSectionName(classSectionNames.join(", "));
        setStudentsList(updatedStudentsList);
    };
    

    const actionBodyTemplateSrNo = (data, props) => {
        return props.rowIndex + 1;
    };

    return (
        <div className="datatable-filter">
            <div className="card">
                <div className="p-toolbar p-toolbar-group-left">
                    <h3>ID Card Master</h3>
                    <div className="p-toolbar p-toolbar-group-right">
                        <div className="p-input-icon-left global-search">
                        <MultiSelect id="className" 
                        value={classId} options={classList} onChange={(e) => onChangeClass(e)} placeholder="Please Select Class" 
                        display="chip"
                        filter/>
                        </div>
                        <Button icon="pi pi-plus" className="p-button-rounded p-button-text" onClick={() => setVisible(true)} />
                    </div>
                </div>

                <DataTable
                    value={studentsList}
                    paginator
                    rows={rows}
                    first={first}
                    filters={filters}
                    filterDisplay="menu"
                    globalFilterFields={['Name', 'Email', 'Phone']}
                    responsiveLayout="scroll"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    rowsPerPageOptions={[5, 10, 20]}
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    onPage={onPageChange}
                >
                    <Column field="id" body={actionBodyTemplateSrNo} header="Sr No." />
                    <Column
                        field="Name"
                        header="Student Name"
                        filter
                        filterElement={renderColumnFilter('Name', FilterMatchMode.CONTAINS)}
                    />
                    <Column
                        field="Email"
                        header="Email"
                        filter
                        filterElement={renderColumnFilter('Email', FilterMatchMode.CONTAINS)}
                    />
                    <Column
                        field="Phone"
                        header="Phone"
                        filter
                        filterElement={renderColumnFilter('Phone', FilterMatchMode.CONTAINS)}
                    />
                </DataTable>
            </div>
            <Dialog
                header='Generate Id Card'
                className='dialog-popup'
                visible={visible}
                onHide={() => onCancel()}
                draggable={false}
                position="top"
            >
                <GenerateIdCardDialog masterData={masterData} isEdit={isEdit} ID={classId} onCancel={onCancel} classSectionName={classSectionName} studentsData={studentsList}/>

            </Dialog>
        </div>
    );
};

export default IdCardMaster;
