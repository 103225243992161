import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { FilterMatchMode } from 'primereact/api';
import { Dialog } from 'primereact/dialog';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import ClassConfigurationDialog from './ClassConfiguration';
import SectionChangeDialog from './SectionChange';
import { useNavigate } from 'react-router-dom';


const ClassConfiguration = () => {
    const [classList, setClassList] = useState([]);
    const [masterData, setMasterData] = useState([]);
    const [visible, setVisible] = useState(false);
    const [visibleSection, setVisibleSection] = useState(false);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Class: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [securityUserID, setSecurityUserID] = useState("");
    const [rows, setRows] = useState(10);
    const [first, setFirst] = useState(0);

    useEffect(() => {
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?._id);
        GetClassList();
    }, []);

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const onPageChange = (e) => {
        setFirst(e.first);
        setRows(e.rows);
    };

    const GetClassList = async () => {
        try {
            let data = {
                UserID: securityUserID,
            }
            await api.post(APIConstant.path.getClassList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setClassList(res.data.map((x) => ({
                        ...x,
                        Class: `${x?.ClassName}-${x?.SectionName.charAt(0)}-${x?.Medium}-${x?.BoardName}`
                    })));
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const actionBodyTemplate = (rows) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-cog" className="p-button-rounded p-button-text p-button-primary" onClick={() => {
                    setVisible(true);
                    setMasterData(rows) }} 
                    data-pr-tooltip='Class Configuration' tooltip={"View"} tooltipOptions={{ className: 'bluegray-tooltip', position: 'top' }}/>
                <Button icon="pi pi-refresh" className="p-button-rounded p-button-text p-button-secondary" data-pr-tooltip='Change Section' data-pr-position='top' tooltip={"View"} tooltipOptions={{ className: 'bluegray-tooltip', position: 'top' }} onClick={() => {
                    setVisibleSection(true);
                    setMasterData(rows) }} />
            </React.Fragment>
        );
    };

    const onCancel = () => {
        setVisible(false);
        setVisibleSection(false);
        GetClassList();
    }


    const actionBodyTemplateSrNo = (data, props) => {
        return props.rowIndex + 1;
    };
    return (
        <div className="datatable-filter">
            <div className="card">
                <div className="p-toolbar p-toolbar-group-left">
                    <h3>Class Configuration</h3>
                    <div className="p-toolbar p-toolbar-group-right">
                        <div className="p-input-icon-left global-search">
                            <i className="pi pi-search" />
                            <InputText
                                type="search"
                                value={globalFilterValue}
                                onChange={onGlobalFilterChange}
                                placeholder="Global Search"
                            />
                        </div>
                    </div>
                </div>

                <DataTable
                    value={classList}
                    paginator
                    rows={rows}
                    first={first}
                    filters={filters}
                    filterDisplay="menu"
                    responsiveLayout="scroll"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    rowsPerPageOptions={[5, 10, 20]}
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    onPage={onPageChange}
                >
                    <Column field="id" body={actionBodyTemplateSrNo} header="Sr No." />
                    <Column
                        field="Class"
                        header="Class"
                    />
                    <Column
                        field="UpdatedOn"
                        header="Last Updated On"
                    />
                    <Column body={actionBodyTemplate} header="Action" />
                </DataTable>
            </div>
            <Dialog
                header={"Class- "+ masterData?.Class+ " Config."}
                // className='dialog-popup'
                visible={visible}
                onHide={() => onCancel()}
                draggable={false}
                position="top"
            >
                <ClassConfigurationDialog onCancel={onCancel} configId={masterData?._id}/>

            </Dialog>
            <Dialog
                header='Change Section'
                visible={visibleSection}
                onHide={() => onCancel()}
                draggable={false}
                position="top"
            >
                <SectionChangeDialog onCancel={onCancel} configId={masterData?._id}/>

            </Dialog>
        </div>
    );
};

export default ClassConfiguration;
