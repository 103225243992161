
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import React, { useState,useEffect } from 'react';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import Toast from '../../Shared/Toast/Toast';
import { FloatLabel } from 'primereact/floatlabel';
import { Dialog } from 'primereact/dialog';
import AddSectionMasterDialog from '../SectionMaster/AddSectionMaster';

const AddClassMasterDialog = (props) => {
    const [className, setClassName] = useState('');
    const [visible, setVisible] = useState(false);
    const [securityUserID, setSecurityUserID] = useState("");
    const [board, setBoard] = useState('');
    const [medium, setMedium] = useState('');
    const [sections, setSections] = useState('');
    const [classNameErrText, setClassNameErrText] = useState('');
    const [boardErrText, setBoardErrText] = useState('');
    const [mediumErrText, setMediumErrText] = useState('');
    const [classNameErr, setClassNameErr] = useState(false);
    const [boardErr, setBoardErr] = useState(false);
    const [mediumErr, setMediumErr] = useState(false);
    const [studentsCount, setStudentsCount] = useState('');
    const [studentsCountErr, setStudentsCountErr] = useState(false);
    const [studentsCountErrText, setStudentsCountErrText] = useState('');
    const [totalCapacity, setTotalCapacity] = useState('');
    const [totalCapacityErr, setTotalCapacityErr] = useState(false);
    const [totalCapacityErrText, setTotalCapacityErrText] = useState('');
    const [sectionsList,setSectionList] = useState([]);
    const mediumList = [
        { label: 'English', value: 'English' },
        { label: 'Hindi', value: 'Hindi' }
    ];
    const [boardList, setBoardList] = useState([]);
    const [studentsList, setStudentsList] = useState([]);

    useEffect(() => {
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?._id);
        GetSectionDetailList();
        GetBoardList();
        GetStudentsList();
        if(props.ID){
            setClassName(props?.masterData?.ClassName);
            setBoard(props?.masterData?.BoardName);
            setMedium(props?.masterData?.Medium);
            setSections(props?.masterData?.SectionName);
            setStudentsCount(props?.masterData?.StudentsCount?.split(',') || []);
            setTotalCapacity(props?.masterData?.TotalCapacity);
        }
    
    },[]);

    const onCancel = () => {
        props.onCancel();
        setVisible(false);
        setClassName('');
        setBoard('');
        setMedium('');
        setStudentsCount('');
        setTotalCapacity('');
        setSections([]);
        GetSectionDetailList();
    }

    const GetBoardList = async () => {
        try {
            let data = {
                UserID: securityUserID,
            }
            await api.post(APIConstant.path.getBoardList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setBoardList(res.data.map(x => ({
                        ...x,
                        label: x?.BoardName,
                        value: x?._id
                    })));
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const GetStudentsList = async () => {
        try {
            let data = {
                UserID: securityUserID,
            }
            await api.post(APIConstant.path.getStudentDetailList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setStudentsList(res.data.map(x => ({
                        ...x,
                        label: x?.Name,
                        value: x?._id
                    })));
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const GetSectionDetailList = async () => {
        try {
            let data = {
                UserID: securityUserID,
            }
            await api.post(APIConstant.path.getSectionList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setSectionList(res.data);
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const validate = () => {
        let formIsValid = true;
        if (CommonConfig.isEmpty(className)) {
            formIsValid = false;
            setClassNameErr(true);
            setClassNameErrText("Class Name is required");
        } else {
            setClassNameErr(false);
            setClassNameErrText("");
        }
        if (CommonConfig.isEmpty(board)) {
            formIsValid = false;
            setBoardErr(true);
            setBoardErrText("Board is required");
        } else {
            setBoardErr(false);
            setBoardErrText("");
        }

        if (CommonConfig.isEmpty(medium)) {
            formIsValid = false;
            setMediumErr(true);
            setMediumErrText("Medium is required");
        } else {
            setMediumErr(false);
            setMediumErrText("");
        }
        return formIsValid;
    };

    const AddClassMaster = async (e) => {
        if (validate(e)) {
            try {
                Loader.show();
                let data = {
                    id: props.ID,
                    ClassName: className,
                    BoardName: board,
                    Medium: medium,
                    SectionName: sections,
                    StudentsCount: studentsCount.length > 1 ? studentsCount.join(',') : studentsCount[0],
                    TotalCapacity: totalCapacity,
                    CreatedBy: securityUserID,
                };

                await api
                    .post(APIConstant.path.AddUpdateClassMaster, data)
                    .then((response) => {
                        let res = response;
                        if (res.success) {
                            Toast.success({ message: res.message });
                            Loader.hide();
                            onCancel();
                        } else {
                            Toast.error({ message: res.message });
                            Loader.hide();
                            onCancel();
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        Loader.hide();
                        onCancel();
                    });
            } catch (err) {
                console.log(err);
                Loader.hide();
                onCancel();
            }
        }
    };

    return (
        <div className="user-container p-d-flex p-flex-column p-jc-between">
            <div>
                <div className="p-col-12 p-md-4 p-pt-2">
                    <div className="">
                        <FloatLabel>
                            <label htmlFor="clasName">Class Name </label>
                            <InputText
                                className="p-mt-2"
                                id="className"
                                type="text"
                                placeholder="Class Name"
                                value={className}
                                autoFocus={true}
                                onChange={(e) => setClassName(e.target.value)}
                            />
                        </FloatLabel>
                        <span className="error-msg" style={{ color: 'red' }}>
                            {classNameErr ? classNameErrText : null}
                        </span>
                    </div>
                </div>
                <div className="p-col-12  p-md-3 p-pt-2">
                    <div className="">
                        <div className="custom-inputs">
                            <label htmlFor="Board" className="mr-2">Board</label>
                            <Dropdown id="board" value={board} options={boardList} onChange={(e) => setBoard(e.value)} placeholder="Select Board" />
                        </div>
                        <span className="p-error block" style={{ color: 'red' }}>
                            {boardErr ? boardErrText : null}
                        </span>
                    </div>
                </div>
                <div className="p-col-12  p-md-3 p-pt-2">
                    <div className="">
                        <div className="custom-inputs">
                            <label htmlFor="Medium" className="mr-2">Medium</label>
                            <Dropdown
                                className="p-mt-2"
                                inputId="medium"
                                placeholder="Select Medium"
                                value={medium}
                                onChange={(e) => setMedium(e.value)}
                                options={mediumList}
                                optionLabel="label"
                                filter
                            />
                        </div>
                        <span className="p-error block" style={{ color: 'red' }}>
                            {mediumErr ? mediumErrText : null}
                        </span>
                    </div>
                </div>
                <div className="p-col-12  p-md-3 p-pt-2">
                    <div className="">
                        <div className="custom-inputs">
                            <label htmlFor="sections" className="mr-2">Sections</label>
                            <Dropdown
                                id="sections"
                                value={sections}
                                options={sectionsList}
                                onChange={(e) => setSections(e.value)}
                                placeholder="Select Sections"
                                className="mr-2"
                                display="chip"
                            />
                            <Button
                                icon="pi pi-plus"
                                className="p-button-rounded p-button-text"
                                onClick={(e) => setVisible(true)}
                            />
                        </div>
                    </div>
                </div>
                <div className="p-col-12 p-md-4 p-pt-2">
                    <div className="">
                            <label htmlFor="studentsCount">Students Name: </label>
                            <MultiSelect
                                id="studentList"
                                value={studentsCount}
                                options={studentsList}
                                onChange={(e) => setStudentsCount(e.value)}
                                placeholder="Select Student"
                                className="mr-2"
                                filter
                                display="chip"
                            />
                        <span className="error-msg" style={{ color: 'red' }}>
                            {studentsCountErr ? studentsCountErrText : null}
                        </span>
                    </div>
                </div>
                <div className="p-col-12 p-md-4 p-pt-2">
                    <div className="">
                        <FloatLabel>
                            <label htmlFor="totalCapacity">Total Capacity : </label>
                            <InputText
                                className="p-mt-2"
                                id="totalCapacity"
                                type="number"
                                placeholder="Total Capacity"
                                value={totalCapacity}
                                autoFocus={true}
                                onChange={(e) => setTotalCapacity(e.target.value)}
                            />
                        </FloatLabel>
                        <span className="error-msg" style={{ color: 'red' }}>
                            {totalCapacityErr ? totalCapacityErrText : null}
                        </span>
                    </div>
                </div>

            </div>
            <div className="card-footer">
                <Button label="Submit" severity="secondary" raised onClick={(e) => AddClassMaster()} />
                <Button className='btn-cancel' onClick={(e) => onCancel()}> cancel </Button>
            </div>
            <Dialog
                header="Add Section Master"
                className='dialog-popup'
                visible={visible}
                onHide={() => onCancel()}
                draggable={false}
                
                position="top"
            >
                <AddSectionMasterDialog masterData={[]} isEdit={false} ID={''} onCancel={onCancel} />

            </Dialog>
        </div>
    );
};

export default AddClassMasterDialog;
